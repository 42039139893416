import Grid from "@mui/material/Grid/Grid";
import TextField from "@mui/material/TextField";
import * as React from "react";
import HoursField from "~components/HoursField";

interface ComponentProps {
  hours: number;
  circles: number;
  hoursIncrement: number | null;
  circlesIncrement: number | null;
  onHoursIncrementChange: (value: number | null) => void;
  onCirclesIncrementChange: (value: number | null) => void;
}

function CountersForm(props: ComponentProps) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        Hours:
      </Grid>
      <Grid item xs={3}>
        <HoursField
          required
          disabled
          label="Current"
          value={props.hours}
          fullWidth
          variant="standard"
        />
      </Grid>
      <Grid item xs={3}>
        <HoursField
          label="Increment"
          value={props.hoursIncrement || 0}
          onChange={props.onHoursIncrementChange}
          inputLabelProps={{ shrink: true }}
          fullWidth
          variant="standard"
        />
      </Grid>
      <Grid item xs={3}>
        <HoursField
          required
          disabled
          label="Final"
          value={props.hours + (props.hoursIncrement || 0)}
          fullWidth
          variant="standard"
        />
      </Grid>
      <Grid item xs={3}>
        Circles:
      </Grid>
      <Grid item xs={3}>
        <TextField
          disabled
          type="number"
          label="Current"
          value={props.circles}
          fullWidth
          variant="standard"
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          type="number"
          label="Increment"
          value={props.circlesIncrement || ""}
          onChange={(event) =>
            props.onCirclesIncrementChange(
              event.currentTarget.value !== ""
                ? Number(event.currentTarget.value)
                : null
            )
          }
          InputLabelProps={{ shrink: true }}
          fullWidth
          variant="standard"
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          disabled
          type="number"
          label="Final"
          value={props.circles + (props.circlesIncrement || 0)}
          fullWidth
          variant="standard"
        />{" "}
      </Grid>
    </Grid>
  );
}
export default CountersForm;
