import { useMutation } from "@apollo/client";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import format from "date-fns/format";
import * as React from "react";
import { gql } from "~_generated/gql";
import { locale } from "~config";
import { LIST_WORK_ORDERS } from "~routes/WorkOrders/ListWorkOrdersPage";
import { LIST_WORK_ORDER_TASKS } from "./ListWorkOrderTasksPage";

export const COMPLETE_WORK_ORDER = gql(`
  mutation CompleteWorkOrder($id: ID!, $data: UpdateWorkOrderInput!) {
    updateWorkOrder(id: $id, data: $data) {
      id
    }
  }
`);

interface Props {
  id: string;
  completedAt?: Date;
  completable: boolean;
}

function WorkOrderStatus({ id, completedAt, completable }: Props) {
  const [complete, { error, loading }] = useMutation(COMPLETE_WORK_ORDER);

  const handleCompletion = () => {
    complete({
      variables: { id, data: { completedAt: new Date() } },
      refetchQueries: [LIST_WORK_ORDERS, LIST_WORK_ORDER_TASKS],
    });
  };

  if (completedAt) {
    return (
      <>
        <Typography>Work order was completed</Typography>
        <Typography>
          {format(new Date(completedAt), "Pp", {
            locale,
          })}
        </Typography>
      </>
    );
  } else {
    return (
      <>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error?.message}
          </Alert>
        )}
        <Tooltip title={!completable && "Need to complete all the tasks first"}>
          <span>
            <Button
              color="primary"
              variant="contained"
              disabled={!completable || loading}
              onClick={handleCompletion}
            >
              Complete
            </Button>
          </span>
        </Tooltip>
      </>
    );
  }
}

export default WorkOrderStatus;
