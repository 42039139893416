import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { useMemo, useState } from "react";
import { StackedInstruction } from "~_generated/graphql";
import NextCounterValue from "./NextCounterValue";
import NextDateValue from "./NextDateValue";
import WorkOrderCreator from "./WorkOrderCreator";

interface Props {
  maintainableId: string;
  instructions: Array<{
    instruction: Pick<
      StackedInstruction,
      | "instructionId"
      | "index"
      | "maintainableName"
      | "taskDescription"
      | "intervalRule"
      | "modificationIds"
    >;
    maintainable: { id: string };
    next: {
      date?: Date | null;
      hours?: number | null;
      circles?: number | null;
    };
  }>;
  date: Date;
  hours: number;
  circles: number;
}

function WorkOrderPreview({
  instructions,
  maintainableId,
  ...maintenance
}: Props) {
  const [unselected, setUnselected] = useState<Array<string>>([]);

  const selected = useMemo(() => {
    return instructions
      .map(({ instruction, maintainable }) => ({
        maintainableId: maintainable.id,
        instructionId: instruction.instructionId,
        instructionModificationIds: instruction.modificationIds,
      }))
      .filter((task) => !unselected.includes(task.instructionId));
  }, [instructions, unselected]);

  const handleToggleSelection = (id: string) => {
    setUnselected((selection) => {
      if (selection.includes(id)) {
        return [...selection.filter((itemId) => itemId !== id)];
      } else {
        return [...selection, id];
      }
    });
  };

  return (
    <Stack spacing={3}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Task No</TableCell>
              <TableCell>Item name</TableCell>
              <TableCell>Task</TableCell>
              <TableCell>Next date</TableCell>
              <TableCell>Next in hours</TableCell>
              <TableCell>Next in circles</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {instructions.map(({ instruction, next }) => (
              <TableRow
                key={instruction.instructionId}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {instruction.index}
                </TableCell>
                <TableCell>{instruction.maintainableName}</TableCell>
                <TableCell>{instruction.taskDescription}</TableCell>
                <TableCell>
                  {next.date && (
                    <NextDateValue
                      date={next.date}
                      maintenanceDate={maintenance.date}
                    />
                  )}
                </TableCell>
                <TableCell>
                  {next.hours && (
                    <NextCounterValue
                      counter={next.hours}
                      maintenanceCounter={maintenance.hours}
                    />
                  )}
                </TableCell>
                <TableCell>
                  {next.circles && (
                    <NextCounterValue
                      counter={next.circles}
                      maintenanceCounter={maintenance.circles}
                    />
                  )}
                </TableCell>
                <TableCell>
                  <Checkbox
                    checked={!unselected.includes(instruction.instructionId)}
                    onChange={() =>
                      handleToggleSelection(instruction.instructionId)
                    }
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <WorkOrderCreator maintainableId={maintainableId} tasks={selected} />
    </Stack>
  );
}
export default WorkOrderPreview;
