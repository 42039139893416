import { gql } from "@apollo/client";
import client from "~api/client";

export async function requestUser() {
  const token = localStorage.getItem("aeroToken");
  if (token) {
    return client
      .query({
        query: gql`
          query CurrentUser {
            currentUser {
              id
              firstName
              lastName
              username
            }
          }
        `,
      })
      .then((result) => {
        return result.data.currentUser;
      });
  } else {
    return null;
  }
}

export async function login(username: string, password: string) {
  return client
    .mutate({
      mutation: gql`
        mutation Login($username: String!, $password: String!) {
          login(username: $username, password: $password) {
            token
          }
        }
      `,
      variables: { username, password },
    })
    .then((result) => {
      const token = result.data.login.token;
      if (token) {
        localStorage.setItem("aeroToken", token);
      } else {
        throw new Error("Wrong username or password");
      }
    });
}

export function logout() {
  client.resetStore();
  localStorage.removeItem("aeroToken");
}
