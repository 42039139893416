import { useMutation, useQuery } from "@apollo/client";
import { Delete, Edit } from "@mui/icons-material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ListAltIcon from "@mui/icons-material/ListAlt";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid/Grid";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import format from "date-fns/format";
import * as React from "react";
import { Link } from "react-router-dom";
import { gql } from "~_generated/gql";
import { Component, Vehicle } from "~_generated/graphql";
import { DeleteConfirmationModal } from "~components/modals";
import config from "~config";
import { PageContainer } from "~layout";
import paths from "~routes/paths";
import DocumentsModal from "./DocumentsModal";

export const LIST_WORK_ORDERS = gql(`
  query WorkOrders {
    workOrders {
      items {
        id
        maintainable {
          id
          owner {
            ... on Vehicle {
              id
              model
              serial
            }
            ... on Component {
              id
              ataNumber
              name
            }
          }
        }
        name
        completedAt
      }
    }
  }
`);

export const DELETE_WORK_ORDER = gql(`
  mutation DeleteWorkOrder($id: ID!) {
    deleteWorkOrder(id: $id)
  }
`);
type VehiclePartial = Pick<Vehicle, "model" | "serial">;
type ComponentPartial = Pick<Component, "ataNumber" | "name">;

function isComponent(
  obj: VehiclePartial | ComponentPartial
): obj is ComponentPartial {
  return obj.hasOwnProperty("ataNumber");
}
function renderOwnerName(owner: VehiclePartial | ComponentPartial) {
  if (isComponent(owner)) {
    return (
      <>
        {owner.ataNumber}. {owner.name}
      </>
    );
  } else {
    return (
      <>
        {owner.model}: {owner.serial}
      </>
    );
  }
}

function ListWorkOrdersPage() {
  const { data, error, loading, refetch } = useQuery(LIST_WORK_ORDERS);
  const [deleteWorkOrder, { loading: deleting, error: deleteError }] =
    useMutation(DELETE_WORK_ORDER);

  const [itemToDelete, setItemToDelete] = React.useState<{
    id: string;
    name: string;
  }>();
  const [itemForDocuments, setItemForDocuments] = React.useState<{
    id: string;
    name: string;
    completedAt?: Date;
  }>();

  const workOrders = data?.workOrders?.items || [];

  const handleDelete = (id: string) => {
    deleteWorkOrder({ variables: { id } })
      .then(() => refetch())
      .finally(() => setItemToDelete(undefined));
  };

  return (
    <PageContainer loading={loading} error={error?.message}>
      {deleteError && <Alert severity="error">{deleteError?.message}</Alert>}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Vehicle/Component</TableCell>
                  <TableCell>Completed at</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {workOrders.map((workOrder) => (
                  <TableRow
                    key={workOrder.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>{workOrder.name}</TableCell>
                    <TableCell sx={{ minWidth: "12rem" }}>
                      {workOrder.maintainable.owner &&
                        renderOwnerName(workOrder.maintainable.owner)}
                    </TableCell>
                    <TableCell>
                      {workOrder.completedAt &&
                        format(new Date(workOrder.completedAt), "P", {
                          locale: config.locale,
                        })}
                    </TableCell>
                    <TableCell
                      sx={{ whiteSpace: "nowrap", textAlign: "right" }}
                    >
                      <IconButton
                        aria-label="export"
                        href={`${config.exportUrl}/workOrders/${workOrder.id}`}
                      >
                        <FileDownloadIcon />
                      </IconButton>
                      <IconButton
                        aria-label="attachments"
                        onClick={() => setItemForDocuments(workOrder)}
                      >
                        <AttachFileIcon />
                      </IconButton>
                      <IconButton
                        component={Link}
                        to={paths.listWorkOrderTasks(workOrder.id)}
                        aria-label="view tasks"
                      >
                        <ListAltIcon />
                      </IconButton>
                      <IconButton
                        component={Link}
                        to={paths.editWorkOrder(workOrder.id)}
                        aria-label="edit"
                      >
                        <Edit />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        onClick={() => setItemToDelete(workOrder)}
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      {itemToDelete && (
        <DeleteConfirmationModal
          open={Boolean(itemToDelete)}
          deleting={deleting}
          itemName={itemToDelete.name}
          onCancel={() => setItemToDelete(undefined)}
          onConfirm={() => handleDelete(itemToDelete.id)}
        />
      )}
      {itemForDocuments && (
        <DocumentsModal
          workOrderId={itemForDocuments.id}
          open={true}
          disabled={Boolean(itemForDocuments.completedAt)}
          onCancel={() => setItemForDocuments(undefined)}
        />
      )}
    </PageContainer>
  );
}

export default ListWorkOrdersPage;
