import defaultLocale from "date-fns/locale/en-GB";
export const locale = defaultLocale;

export const apiUrl =
  process.env.REACT_APP_API_URL || "http://localhost:8000/graphql";

export const exportUrl =
  process.env.REACT_APP_EXPORT_URL || "http://localhost:8000/export";

export const uploadUrl =
  process.env.REACT_APP_UPLOAD_URL || "http://localhost:8000/upload";

const config = {
  locale,
  apiUrl,
  exportUrl,
  uploadUrl,
};
export default config;
