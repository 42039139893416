import { useMutation, useQuery } from "@apollo/client";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import * as React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { gql } from "~_generated/gql";
import { PageContainer, PageHeadline } from "~layout";
import paths from "~routes/paths";
import { LIST_WORK_ORDER_TASKS } from "~routes/Tasks/ListWorkOrderTasksPage";
import TaskForm, { TaskFormData } from "~routes/Tasks/TaskForm";

export const GET_TASK = gql(`
  query Task($id: ID!) {
    task(id: $id) {
      id
      workOrderId
      instructionId
      index
      maintainableName
      maintainableIndex
      taskDescription
      notes
      manuals
      references
      tools
      materials
    }
  }
`);

export const UPDATE_TASK = gql(`
  mutation UpdateTask($id: ID!, $data: UpdateTaskInput!) {
    updateTask(id: $id, data: $data) {
        id
      }
    }
`);

function EditTaskPage() {
  const navigate = useNavigate();
  const params = useParams();
  const taskId = params.id || "";
  const { data, loading, error } = useQuery(GET_TASK, {
    variables: { id: taskId },
    fetchPolicy: "network-only",
  });
  const task = data?.task || undefined;
  const [update, { loading: updateLoading, error: updateError }] =
    useMutation(UPDATE_TASK);

  const handleSubmit = (data: TaskFormData) => {
    const variables = {
      id: taskId,
      data,
    };
    update({
      variables,
      refetchQueries: [LIST_WORK_ORDER_TASKS],
    }).then(() => navigate(paths.listWorkOrderTasks(task?.workOrderId || "")));
  };

  return (
    <PageContainer loading={loading} error={error?.message}>
      <PageHeadline>Updating task {task?.index}</PageHeadline>
      {task?.instructionId && (
        <Alert severity="warning">
          Task is linked with instruction. Editing is not advised!
        </Alert>
      )}
      {updateError && <Alert severity="error">{updateError?.message}</Alert>}
      {task && (
        <TaskForm data={task} onSubmit={handleSubmit}>
          <Button
            component={Link}
            to={paths.listWorkOrderTasks(task.workOrderId || "")}
            variant="contained"
            color="secondary"
            sx={{ mr: 1 }}
          >
            Back
          </Button>
          <Button type="submit" variant="contained" disabled={updateLoading}>
            Save changes
          </Button>
        </TaskForm>
      )}
    </PageContainer>
  );
}

export default EditTaskPage;
