import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import format from "date-fns/format";
import * as React from "react";
import { Component } from "~_generated/graphql";
import { locale } from "~config";

interface Props {
  components: Array<Omit<Component, "vehicleId">>;
}

function UploadPreview({ components }: Props) {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>ATA No</TableCell>
            <TableCell>Position</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Part No</TableCell>
            <TableCell>Serial No</TableCell>
            <TableCell>Keyword</TableCell>
            <TableCell>Installed date</TableCell>
            <TableCell>Installed hours</TableCell>
            <TableCell>Installed circles</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {components.map((component) => (
            <TableRow
              key={component.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {component.index}
              </TableCell>
              <TableCell>{component.ataNumber}</TableCell>
              <TableCell>{component.position}</TableCell>
              <TableCell>{component.name}</TableCell>
              <TableCell>{component.partNumber}</TableCell>
              <TableCell>{component.serialNumber}</TableCell>
              <TableCell>{component.keyword}</TableCell>
              <TableCell>
                {component.maintainable?.date &&
                  format(new Date(component.maintainable.date), "P", {
                    locale,
                  })}
              </TableCell>
              <TableCell>
                {
                  component.maintainable?.relativeCounters?.find(
                    (item) => item.name === "hours"
                  )?.value
                }
              </TableCell>
              <TableCell>
                {
                  component.maintainable?.relativeCounters?.find(
                    (item) => item.name === "circles"
                  )?.value
                }
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default UploadPreview;
