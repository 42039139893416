import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import * as React from "react";
import { useState } from "react";
import { transformCounters } from "~components/components.utils";

interface ComponentProps {
  open: boolean;
  onConfirm: (
    date: Date | null,
    counters: Array<{ name: string; value: number }> | null
  ) => void;
  onCancel: () => void;
  disabled?: boolean;
  loading?: boolean;
  error?: string;
  date?: Date;
  hours?: number;
  circles?: number;
  name?: string;
}

function EditNextOverrideModal(props: ComponentProps) {
  const [date, setDate] = useState<Date | null>(props.date || null);
  const [hours, setHours] = useState<string>(
    props.hours ? String(props.hours) : ""
  );
  const [circles, setCircles] = useState<string>(
    props.circles ? String(props.circles) : ""
  );

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    props.onConfirm(
      date,
      transformCounters(["hours", "circles"], [hours, circles])
    );
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Override next maintenance
      </DialogTitle>
      <DialogContent>
        <form id="edit-next-override-form" onSubmit={handleSubmit}>
          {props.error && <Alert severity="error">{props.error}</Alert>}
          <DatePicker
            label="Date"
            value={date}
            onChange={(value) => setDate(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                sx={{ pb: 2 }}
                fullWidth
              />
            )}
          />
          <TextField
            type="number"
            label="Hours"
            value={hours}
            onChange={(event) => setHours(event.currentTarget.value)}
            fullWidth
            variant="standard"
          />
          <TextField
            type="number"
            label="Circles"
            value={circles}
            onChange={(event) => setCircles(event.currentTarget.value)}
            fullWidth
            variant="standard"
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onCancel} color="info">
          Cancel
        </Button>
        <Button
          type="submit"
          form="edit-next-override-form"
          disabled={props.loading || props.disabled}
          color="success"
          autoFocus
        >
          Save changes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default EditNextOverrideModal;
