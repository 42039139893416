import { useMutation } from "@apollo/client";
import SettingsIcon from "@mui/icons-material/Settings";
import { Chip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import format from "date-fns/format";
import * as React from "react";
import { useState } from "react";
import { gql } from "~_generated/gql";
import { locale } from "~config";
import { LIST_VEHICLES } from "~routes/Vehicles/ListVehiclesPage";
import EditNextOverrideModal from "./EditNextOverrideModal";
import { LIST_WORK_ORDER_TASKS } from "./ListWorkOrderTasksPage";

export const UPDATE_TASK_OVERRIDES = gql(`
  mutation UpdateTaskOverrides($id: ID!, $data: UpdateTaskInput!) {
    updateTask(id: $id, data: $data) {
      nextOverride {
        date
        counters {
          name
          value
        }
      }
    }
  }
`);
interface Props {
  id: string;
  date?: Date | null;
  hours?: number | null;
  circles?: number | null;
}

function OverrideStatus({ id, date, hours, circles }: Props) {
  const [update, { loading, error }] = useMutation(UPDATE_TASK_OVERRIDES);
  const [open, setOpen] = useState<boolean>(false);

  const handleConfirm = (
    date: Date | null,
    counters: Array<{ name: string; value: number }> | null
  ) => {
    update({
      variables: {
        id,
        data: {
          nextOverride: {
            date: date || undefined,
            counters: counters || undefined,
          },
        },
      },
      refetchQueries: [LIST_WORK_ORDER_TASKS, LIST_VEHICLES],
    }).then(() => setOpen(false));
  };

  return (
    <>
      <Stack direction="row" spacing={1}>
        {date && (
          <Chip
            label={
              date &&
              format(new Date(date), "P", {
                locale,
              })
            }
            color="success"
            variant="outlined"
          />
        )}
        {hours && <Chip label={hours} color="success" variant="outlined" />}
        {circles && <Chip label={circles} color="success" variant="outlined" />}
        <IconButton aria-label="edit" onClick={() => setOpen(true)}>
          <SettingsIcon />
        </IconButton>
      </Stack>
      <EditNextOverrideModal
        loading={loading}
        error={error?.message}
        open={open}
        date={date || undefined}
        hours={hours || undefined}
        circles={circles || undefined}
        onCancel={() => setOpen(false)}
        onConfirm={handleConfirm}
      />
    </>
  );
}

export default OverrideStatus;
