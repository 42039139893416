import { useMutation, useQuery } from "@apollo/client";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import * as React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { gql } from "~_generated/gql";
import { PageContainer, PageHeadline } from "~layout";
import paths from "~routes/paths";
import {
  InstructionModFormData,
  InstructionModificationForm,
  InstructionSelector,
} from "./InstructionModificationForm";
import { LIST_MANUAL_INSTRUCTIONS } from "./ListProgramInstructionsPage";

export const GET_INSTRUCTION_MODIFICATION = gql(`
  query InstructionModification($id: ID!) {
    instructionModification(id: $id) {
      id
      isIgnored
      programId
      program {
        parentId
      }
      instructionId
      index
      maintainableName
      maintainableIndex
      taskDescription
      notes
      manuals
      references
      tools
      materials
      intervalRule {
        counters {
          name
          value
        }
        thresholdCounters {
          name
          value
        }
      }
      parameterizedRule {
        parameter
        rules {
          value
          counters {
            name
            value
          }
          thresholdCounters {
            name
            value
          }
        }
      }
    }
  }
`);

export const UPDATE_INSTRUCTION_MODIFICATION = gql(`
  mutation UpdateInstructionModification($id: ID!, $data: UpdateInstructionModificationInput!) {
    updateInstructionModification(id: $id, data: $data) {
        id
      }  
    }
`);

function EditInstructionModificationPage() {
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id || "";
  const { data, loading, error } = useQuery(GET_INSTRUCTION_MODIFICATION, {
    variables: { id },
    fetchPolicy: "network-only",
  });
  const modification = data?.instructionModification || undefined;
  const programId = modification?.programId;
  const parentProgramId = modification?.program.parentId;
  const instructionId = modification?.instructionId;
  const [update, { loading: updateLoading, error: updateError }] = useMutation(
    UPDATE_INSTRUCTION_MODIFICATION
  );

  const handleSubmit = (data: InstructionModFormData) => {
    const variables = {
      id,
      data,
    };
    update({ variables, refetchQueries: [LIST_MANUAL_INSTRUCTIONS] }).then(() =>
      navigate(paths.listProgramInstructions(modification?.programId || ""))
    );
  };

  return (
    <PageContainer loading={loading} error={error?.message}>
      <PageHeadline>Updating modification of instruction</PageHeadline>
      {updateError && <Alert severity="error">{updateError?.message}</Alert>}
      {instructionId && programId && parentProgramId && (
        <>
          <InstructionSelector
            programId={parentProgramId}
            selectedId={instructionId}
            onSelectId={() => {}}
            disabled
            sx={{ mb: 2 }}
          />
          <InstructionModificationForm
            instructionId={instructionId}
            onSubmit={handleSubmit}
            data={modification}
          >
            <Button
              component={Link}
              to={paths.listProgramInstructions(programId)}
              variant="contained"
              color="secondary"
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Button type="submit" variant="contained" disabled={updateLoading}>
              Save changes
            </Button>
          </InstructionModificationForm>
        </>
      )}
    </PageContainer>
  );
}

export default EditInstructionModificationPage;
