import { useQuery } from "@apollo/client";
import { CircularProgress } from "@mui/material";
import Stack from "@mui/material/Stack";
import * as React from "react";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { gql } from "~_generated/gql";
import { PageContainer, PageHeadline } from "~layout";
import SearchInstructionsForm from "./SearchInstructionsForm";
import WorkOrderPreview from "./WorkOrderPreview";

export const VIEW_VEHICLE_STATUS = gql(`
  query ViewMaintainableStatus(
    $vehicleId: ID!,
    $targetDateTime: DateTime!,
    $marginHours: Float,
    $marginCircles: Int,
    $recursive: Boolean
  ) {
    workOrderWizard(
      vehicleId: $vehicleId, 
      targetDateTime: $targetDateTime,
      marginHours: $marginHours,
      marginCircles: $marginCircles,
      recursive: $recursive
    ) {
      instruction {
        instructionId
        modificationIds
        index
        maintainableName
        maintainableIndex
        taskDescription
        intervalRule {
          counters {
            name
            value
          }
          thresholdCounters {
            name
            value
          }
        }
        parameterizedRule {
          parameter
          rules {
            value
            counters {
              name
              value
            }
            thresholdCounters {
              name
              value
            }
          }
        }
      }
      maintainable {
        id
      }
      next {
        date
        hours
        circles
      }
    }
  }
`);

function CreateWorkOrderPage() {
  const params = useParams();
  const id = params.id || "";
  const maintainableId = params.maintainableId || "";
  const [date, setDate] = useState<Date>(new Date());
  const [hours, setHours] = useState<number>(0);
  const [circles, setCircles] = useState<number>(0);
  const [recursive, setRecursive] = useState<boolean>(false);

  const { data, error, loading } = useQuery(VIEW_VEHICLE_STATUS, {
    variables: {
      vehicleId: id,
      targetDateTime: date,
      marginHours: hours,
      marginCircles: circles,
      recursive,
    },
  });
  const selectedInstructions = useMemo(
    () => data?.workOrderWizard || [],
    [data]
  );

  const handleFormChange = (
    date: Date,
    hours: number,
    circles: number,
    recursive: boolean
  ) => {
    setDate(date);
    setHours(hours);
    setCircles(circles);
    setRecursive(recursive);
  };

  return (
    <PageContainer error={error?.message}>
      <PageHeadline>
        Create new work order {loading && <CircularProgress size={16} />}
      </PageHeadline>
      <Stack spacing={3}>
        <SearchInstructionsForm
          date={date}
          hours={hours}
          circles={circles}
          recursive={recursive}
          onChange={handleFormChange}
        />
        <WorkOrderPreview
          maintainableId={maintainableId}
          instructions={selectedInstructions}
          date={date}
          hours={hours}
          circles={circles}
        />
      </Stack>
    </PageContainer>
  );
}

export default CreateWorkOrderPage;
