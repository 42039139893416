import * as React from "react";
import { useFormatHours } from "~preferences";

interface Props {
  name: string;
  counters?: Array<{ name: string; value: number }> | null;
}

function CounterValue({ name, counters }: Props) {
  const formatHours = useFormatHours();
  const counter = counters && counters.find((item) => item.name === name);
  if (counter) {
    if (name === "hours") {
      return <>{formatHours(counter.value)}</>;
    } else {
      return <>{counter.value}</>;
    }
  } else {
    return null;
  }
}
export default React.memo(CounterValue);
