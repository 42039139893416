import { useMutation } from "@apollo/client";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import * as React from "react";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { gql } from "~_generated/gql";
import { PageContainer, PageHeadline } from "~layout";
import paths from "~routes/paths";
import {
  InstructionModFormData,
  InstructionModificationForm,
  InstructionSelector,
} from "./InstructionModificationForm";
import { LIST_MANUAL_INSTRUCTIONS } from "./ListProgramInstructionsPage";

export const CREATE_INSTRUCTION_MODIFICATION = gql(`
  mutation CreateInstructionModification($data: CreateInstructionModificationInput!) {
    createInstructionModification(data: $data) {
      id
    }
  }
`);

function CreateProgramInstructionModificationPage() {
  const navigate = useNavigate();
  const params = useParams();
  const programId = params.programId || "";
  const parentProgramId = params.parentProgramId || "";
  const [instructionId, setInstructionId] = useState<string>();
  const [create, { loading, error }] = useMutation(
    CREATE_INSTRUCTION_MODIFICATION
  );

  const handleSubmit = (data: InstructionModFormData) => {
    if (instructionId) {
      const variables = {
        data: {
          ...data,
          programId,
          instructionId,
        },
      };
      create({ variables, refetchQueries: [LIST_MANUAL_INSTRUCTIONS] }).then(
        () => {
          navigate(paths.listProgramInstructions(programId));
        }
      );
    }
  };

  return (
    <PageContainer>
      <PageHeadline>Creating instruction modification</PageHeadline>
      {error && <Alert severity="error">{error?.message}</Alert>}
      <InstructionSelector
        programId={parentProgramId}
        selectedId={instructionId}
        onSelectId={setInstructionId}
        sx={{ mb: 2 }}
      />
      {instructionId && (
        <InstructionModificationForm
          instructionId={instructionId}
          onSubmit={handleSubmit}
        >
          <Button
            component={Link}
            to={paths.listProgramInstructions(programId)}
            variant="contained"
            color="secondary"
            sx={{ mr: 1 }}
          >
            Back
          </Button>
          <Button type="submit" variant="contained" disabled={loading}>
            Save changes
          </Button>
        </InstructionModificationForm>
      )}
    </PageContainer>
  );
}

export default CreateProgramInstructionModificationPage;
