import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { format } from "date-fns";
import * as React from "react";
import { useState } from "react";
import { Component, Vehicle } from "~_generated/graphql";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { transformCounters } from "~components/components.utils";
import ParentComponentSelector from "./ParentComponentSelector";
import HoursField from "~components/HoursField";

export type ComponentFormData = Pick<
  Component,
  | "parentId"
  | "isContainer"
  | "ataNumber"
  | "index"
  | "name"
  | "position"
  | "partNumber"
  | "serialNumber"
  | "keyword"
  | "isInstalled"
> & {
  maintainable?: Pick<
    Vehicle["maintainable"],
    "date" | "counters" | "relativeCounters"
  > | null;
};
interface ComponentProps {
  vehicleId: string;
  id?: string;
  data?: ComponentFormData;
  onSubmit: (data: ComponentFormData) => void;
  children: React.ReactNode;
}

function ComponentForm({
  id,
  vehicleId,
  data,
  onSubmit,
  children,
}: ComponentProps) {
  const [parentId, setParentId] = useState<string | null>(
    data?.parentId || null
  );
  const [isContainer, setIsContainer] = useState<boolean>(
    data?.isContainer || false
  );
  const [index, setIndex] = useState<string>(data?.index || "");
  const [name, setName] = useState<string>(data?.name || "");
  const [ataNumber, setAtaNumber] = useState<string>(
    data?.ataNumber ? String(data?.ataNumber) : ""
  );
  const [position, setPosition] = useState<string>(data?.position || "");
  const [partNumber, setPartNumber] = useState<string>(data?.partNumber || "");
  const [serialNumber, setSerialNumber] = useState<string>(
    data?.serialNumber || ""
  );
  const [keyword, setKeyword] = useState<string>(data?.keyword || "");
  const [isInstalled, setIsInstalled] = useState<boolean>(
    data?.isInstalled || false
  );
  const dataRelativeHours = data?.maintainable?.relativeCounters?.find(
    (item) => item.name === "hours"
  );
  const [relativeHours, setRelativeHours] = useState<number>(
    dataRelativeHours ? dataRelativeHours.value : 0
  );
  const dataRelativeCircles = data?.maintainable?.relativeCounters?.find(
    (item) => item.name === "circles"
  );
  const [relativeCircles, setRelativeCircles] = useState<string>(
    dataRelativeCircles ? String(dataRelativeCircles.value) : ""
  );
  const [date, setDate] = useState<Date | null>(
    data?.maintainable?.date ? new Date(data.maintainable.date) : null
  );
  const dataHours = data?.maintainable?.counters?.find(
    (item) => item.name === "hours"
  );
  const [hours, setHours] = useState<number | null>(
    dataHours ? dataHours.value : null
  );
  const dataCircles = data?.maintainable?.counters?.find(
    (item) => item.name === "circles"
  );
  const [circles, setCircles] = useState<string>(
    dataCircles ? String(dataCircles.value) : ""
  );

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit({
      parentId,
      isContainer,
      index,
      name,
      ataNumber: Number(ataNumber),
      position,
      partNumber,
      serialNumber,
      keyword,
      isInstalled,
      maintainable: {
        date: date ? format(date, "yyyy-MM-dd") : undefined,
        counters: transformCounters(["hours", "circles"], [hours, circles]),
        relativeCounters: transformCounters(
          ["hours", "circles"],
          [relativeHours, relativeCircles]
        ),
      },
    });
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <ParentComponentSelector
            label="Parent component"
            vehicleId={vehicleId}
            componentId={id}
            selectedId={parentId}
            onSelectId={setParentId}
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isContainer}
                onChange={() => setIsContainer((state) => !state)}
              />
            }
            label="Is main (have children)"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Index"
            value={index}
            onChange={(event) => setIndex(event.currentTarget.value)}
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            label="Name"
            value={name}
            onChange={(event) => setName(event.currentTarget.value)}
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            type="number"
            label="ATA Number"
            placeholder="Ex: 20"
            value={ataNumber}
            onChange={(event) => setAtaNumber(event.currentTarget.value)}
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Position"
            placeholder="Ex: Left"
            value={position}
            onChange={(event) => setPosition(event.currentTarget.value)}
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            label="Part number"
            value={partNumber}
            onChange={(event) => setPartNumber(event.currentTarget.value)}
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="serialNumber"
            name="serialNumber"
            label="Serial number"
            value={serialNumber}
            onChange={(event) => setSerialNumber(event.currentTarget.value)}
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Keyword"
            value={keyword}
            onChange={(event) => setKeyword(event.currentTarget.value)}
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <HoursField
            required
            label="Installed at flight hours"
            value={relativeHours}
            onChange={(value) => setRelativeHours(value || 0)}
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            type="number"
            label="Installed at flight circles"
            value={relativeCircles}
            onChange={(event) => setRelativeCircles(event.currentTarget.value)}
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePicker
            label="Installed at date"
            value={date}
            onChange={(value) => setDate(value)}
            renderInput={(params) => (
              <TextField {...params} variant="standard" fullWidth />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isInstalled}
                onChange={() => setIsInstalled((state) => !state)}
              />
            }
            label="Is currently installed"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <HoursField
            label="Hours (individual counter)"
            value={hours}
            onChange={setHours}
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            type="number"
            label="Circles (individual counter)"
            value={circles}
            onChange={(event) => setCircles(event.currentTarget.value)}
            fullWidth
            variant="standard"
          />
        </Grid>

        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    </Box>
  );
}

export default ComponentForm;
