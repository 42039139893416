import { useMutation, useQuery } from "@apollo/client";
import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useMemo, useState } from "react";
import { gql } from "~_generated/gql";
import client from "~api/client";
import { VEHICLE_NEXT_MAINTENANCE } from "~api/vehicles";
import { LIST_VEHICLES } from "../ListVehiclesPage";
import CountersForm from "./CountersForm";
import {
  calculateIncrements,
  Increments,
  incrementsToUpdates,
} from "./CountersModal.utils";

export const VEHICLE_PRIMARY_COUNTERS = gql(`
  query VehiclePrimaryCounters($id: ID!) {
    vehicle(id: $id) {
      id
      model
      serial
      maintainable {
        id
        date
        counters {
          name
          value
        }
        relativeToId
        relativeCounters {
          name
          value
        }
      }
      components(filter: {isContainer: true}) {
        items {
          id
          ataNumber
          index
          position
          name
          partNumber
          serialNumber
          maintainable {
            id
            date
            counters {
              name
              value
            }
            relativeToId
            relativeCounters {
              name
              value
            }
          }
        }
      }
    }
  }
`);

export const UPDATE_MAINTAINABLE = gql(`
  mutation UpdateMaintainable($id: ID!, $data: ChangeMaintainable!) {
    updateMaintainable(id: $id, data: $data) {
      id
    }
  }
`);

interface ComponentProps {
  vehicleId: string;
  open: boolean;
  onCancel: () => void;
}

interface TreeItem<Counter extends string = string> {
  id: string;
  ownerName: string;
  counters: Record<Counter, number | null>;
  relativeToId?: string;
}

function CountersModal(props: ComponentProps) {
  const [increments, setIncrements] = useState<Increments>({});
  const { data, loading } = useQuery(VEHICLE_PRIMARY_COUNTERS, {
    variables: { id: props.vehicleId },
  });
  const [updateMaintainable, { error }] = useMutation(UPDATE_MAINTAINABLE);

  const countersTree: Array<TreeItem> = useMemo(() => {
    const tree: Array<TreeItem> = [];
    if (data?.vehicle) {
      const vehicle = data?.vehicle;
      tree.push({
        id: vehicle.maintainable.id,
        ownerName: `${vehicle.model}: ${vehicle.serial}`,
        counters: {
          hours:
            vehicle.maintainable.counters?.find((item) => item.name === "hours")
              ?.value || null,
          circles:
            vehicle.maintainable.counters?.find(
              (item) => item.name === "circles"
            )?.value || null,
        },
      });
      const components = vehicle?.components?.items;
      if (components) {
        for (const component of components) {
          if (component.maintainable) {
            tree.push({
              id: component.maintainable.id,
              relativeToId: component.maintainable.relativeToId || undefined,
              ownerName: `${component.index}: ${component.name}`,
              counters: {
                hours:
                  component.maintainable.counters?.find(
                    (item) => item.name === "hours"
                  )?.value || null,
                circles:
                  component.maintainable.counters?.find(
                    (item) => item.name === "circles"
                  )?.value || null,
              },
            });
          }
        }
      }
    }
    return tree;
  }, [data]);

  const handleChangeIncrement = (
    id: string,
    counter: "circles" | "hours",
    value: number | null
  ) => {
    setIncrements((records) =>
      calculateIncrements(records, countersTree, counter, id, value)
    );
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const updates = incrementsToUpdates(increments, countersTree);
    Promise.all(
      updates.map(({ id, counters }) =>
        updateMaintainable({ variables: { id, data: { counters } } })
      )
    ).then(() => {
      client.refetchQueries({
        include: [
          LIST_VEHICLES,
          VEHICLE_PRIMARY_COUNTERS,
          VEHICLE_NEXT_MAINTENANCE,
        ],
      });
      props.onCancel();
    });
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {countersTree[0] && countersTree[0].ownerName}
      </DialogTitle>
      <DialogContent>
        {countersTree && (
          <form id="counters-form" onSubmit={handleSubmit}>
            {countersTree.map((item, index) => (
              <Box key={item.id}>
                {index !== 0 && (
                  <Typography sx={{ mt: 3, mb: 1 }}>
                    {item.ownerName}
                  </Typography>
                )}
                <CountersForm
                  hours={Number(item.counters.hours)}
                  circles={Number(item.counters.circles)}
                  hoursIncrement={increments[item.id]?.hours || null}
                  circlesIncrement={increments[item.id]?.circles || null}
                  onHoursIncrementChange={(value) =>
                    handleChangeIncrement(item.id, "hours", value)
                  }
                  onCirclesIncrementChange={(value) =>
                    handleChangeIncrement(item.id, "circles", value)
                  }
                />
              </Box>
            ))}
          </form>
        )}
        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error?.message}
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onCancel} color="info">
          Cancel
        </Button>
        <Button
          type="submit"
          form="counters-form"
          disabled={loading || !data}
          color="success"
          autoFocus
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default CountersModal;
