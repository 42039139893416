import { useMutation } from "@apollo/client";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import * as React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { gql } from "~_generated/gql";
import { PageContainer, PageHeadline } from "~layout";
import paths from "~routes/paths";
import InstructionForm, { InstructionFormData } from "./InstructionForm";
import { LIST_MANUAL_INSTRUCTIONS } from "./ListProgramInstructionsPage";

export const CREATE_INSTRUCTION = gql(`
  mutation CreateInstruction($data: CreateInstructionInput!) {
    createInstruction(data: $data) {
      id
    }
  }`);

function CreateProgramInstructionPage() {
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id || "";
  const [create, { loading, error }] = useMutation(CREATE_INSTRUCTION);

  const handleSubmit = (data: InstructionFormData) => {
    const variables = {
      data: {
        ...data,
        programId: id,
      },
    };
    create({ variables, refetchQueries: [LIST_MANUAL_INSTRUCTIONS] }).then(() =>
      navigate(paths.listProgramInstructions(id))
    );
  };

  return (
    <PageContainer>
      <PageHeadline>Creating instruction for program</PageHeadline>
      {error && <Alert severity="error">{error?.message}</Alert>}
      <InstructionForm onSubmit={handleSubmit}>
        <Button
          component={Link}
          to={paths.listProgramInstructions(id)}
          variant="contained"
          color="secondary"
          sx={{ mr: 1 }}
        >
          Back
        </Button>
        <Button type="submit" variant="contained" disabled={loading}>
          Save changes
        </Button>
      </InstructionForm>
    </PageContainer>
  );
}

export default CreateProgramInstructionPage;
