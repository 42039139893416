import { useQuery } from "@apollo/client";
import AddCircle from "@mui/icons-material/AddCircle";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid/Grid";
import * as React from "react";
import { Link, useParams } from "react-router-dom";
import { GET_VEHICLE } from "~api/vehicles";
import { PageContainer, PageHeadline } from "~layout";
import paths from "~routes/paths";
import ComponentsList from "./ComponentsList";

function ListVehiclesPage() {
  const params = useParams();
  const id = params.id || "";
  const { data, error, loading } = useQuery(GET_VEHICLE, {
    variables: { vehicleId: id },
  });
  const vehicle = data?.vehicle;
  const maintainableId = vehicle?.maintainableId || "";

  return (
    <PageContainer loading={loading} error={error?.message}>
      <PageHeadline>
        {vehicle?.model} / {vehicle?.serial}
      </PageHeadline>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ComponentsList vehicleId={id} parentId={null} />
        </Grid>
        <Grid item xs={12}>
          <Button
            startIcon={<AddCircle />}
            component={Link}
            to={paths.createVehicleComponent(id, maintainableId)}
            variant="contained"
            color="secondary"
            sx={{ mr: 2 }}
          >
            Add Component
          </Button>
          <Button
            component={Link}
            to={paths.uploadVehicleComponents(id, maintainableId)}
            variant="contained"
            color="secondary"
          >
            Upload .csv
          </Button>
        </Grid>
      </Grid>
    </PageContainer>
  );
}

export default ListVehiclesPage;
