import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import * as React from "react";
import { useState } from "react";
import { Instruction } from "~_generated/graphql";
import RulesEditor from "~routes/Instructions/RulesEditor";

export type InstructionFormData = Pick<
  Instruction,
  | "index"
  | "maintainableName"
  | "maintainableIndex"
  | "taskDescription"
  | "notes"
  | "manuals"
  | "references"
  | "tools"
  | "materials"
  | "intervalRule"
  | "parameterizedRule"
>;
interface ComponentProps {
  data?: InstructionFormData;
  onSubmit: (data: InstructionFormData) => void;
  children: React.ReactNode;
}

function InstructionForm({ data, onSubmit, children }: ComponentProps) {
  const [index, setIndex] = useState<string>(data?.index || "");
  const [maintainableName, setMaintainableName] = useState<string>(
    data?.maintainableName || ""
  );
  const [maintainableIndex, setMaintainableIndex] = useState<string>(
    data?.maintainableIndex || ""
  );
  const [taskDescription, setTaskDescription] = useState<string>(
    data?.taskDescription || ""
  );
  const [notes, setNotes] = useState<string>(data?.notes || "");
  const [manuals, setManuals] = useState<string>(data?.manuals || "");
  const [references, setReferences] = useState<string>(data?.references || "");
  const [tools, setTools] = useState<string>(data?.tools || "");
  const [materials, setMaterials] = useState<string>(data?.materials || "");
  const [rules, setRules] = useState<
    Pick<Instruction, "intervalRule" | "parameterizedRule"> | undefined
  >({
    intervalRule: data?.intervalRule,
    parameterizedRule: data?.parameterizedRule,
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit({
      index,
      maintainableName,
      maintainableIndex: maintainableIndex || null,
      taskDescription,
      notes: notes || null,
      manuals: manuals || null,
      references: references || null,
      tools: tools || null,
      materials: materials || null,
      ...rules,
    });
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            label="Task No"
            value={index}
            onChange={(event) => setIndex(event.currentTarget.value)}
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Item index"
            value={maintainableIndex}
            onChange={(event) =>
              setMaintainableIndex(event.currentTarget.value)
            }
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            required
            label="Item name"
            value={maintainableName}
            onChange={(event) => setMaintainableName(event.currentTarget.value)}
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            required
            multiline
            label="Task Description"
            value={taskDescription}
            onChange={(event) => setTaskDescription(event.currentTarget.value)}
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            multiline
            label="Notes"
            value={notes}
            onChange={(event) => setNotes(event.currentTarget.value)}
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            multiline
            label="Manuals"
            value={manuals}
            onChange={(event) => setManuals(event.currentTarget.value)}
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            multiline
            label="Reference documents"
            value={references}
            onChange={(event) => setReferences(event.currentTarget.value)}
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            multiline
            label="Tools"
            value={tools}
            onChange={(event) => setTools(event.currentTarget.value)}
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            multiline
            label="Materials"
            value={materials}
            onChange={(event) => setMaterials(event.currentTarget.value)}
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <RulesEditor onChange={setRules} data={rules} />
        </Grid>
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    </Box>
  );
}

export default InstructionForm;
