import { useMutation, useQuery } from "@apollo/client";
import AddCircle from "@mui/icons-material/AddCircle";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid/Grid";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { gql } from "~_generated/gql";
import { DeleteConfirmationModal } from "~components/modals";
import config from "~config";
import { PageContainer, PageHeadline } from "~layout";
import paths from "~routes/paths";
import DocumentsModal from "~routes/WorkOrders/DocumentsModal";
import CompletionStatus from "./CompletionStatus";
import InspectionStatus from "./InspectionStatus";
import OverrideStatus from "./OverrideStatus";
import WorkOrderStatus from "./WorkOrderStatus";

export const LIST_WORK_ORDER_TASKS = gql(`
  query WorkOrderTasks($id: ID!) {
    workOrder(id: $id) {
      id
      name
      completedAt
      tasks {
        items {
          id
          instructionId
          index
          maintainableName
          maintainableIndex
          taskDescription
          completedBy
          completedAt
          inspectedBy
          inspectedAt
          nextOverride {
            date
            counters {
              name
              value
            }
          }
        }
      }
    }
  }
`);

export const DELETE_TASK = gql(`
  mutation DeleteTask($id: ID!) {
    deleteTask(id: $id)
  }`);

function ListWorkOrderTasksPage() {
  const params = useParams();
  const id = params.id || "";
  const { data, error, loading } = useQuery(LIST_WORK_ORDER_TASKS, {
    variables: { id },
  });
  const [deleteTask, { loading: deleting, error: deleteError }] =
    useMutation(DELETE_TASK);

  const [itemToDelete, setItemToDelete] = React.useState<{
    id: string;
    index: string;
  }>();
  const [docsOpened, setDocsOpened] = useState(false);

  const handleDelete = (id: string) => {
    deleteTask({
      variables: { id },
      refetchQueries: [LIST_WORK_ORDER_TASKS],
    }).finally(() => setItemToDelete(undefined));
  };

  const workOrder = data?.workOrder;
  const tasks = useMemo(() => workOrder?.tasks?.items || [], [workOrder]);
  const completable: boolean = useMemo(() => {
    return !tasks.find(
      (item) =>
        !(
          item.completedAt &&
          item.completedBy &&
          item.inspectedAt &&
          item.inspectedBy
        )
    );
  }, [tasks]);

  return (
    <PageContainer loading={loading} error={error?.message}>
      {deleteError && <Alert severity="error">{deleteError?.message}</Alert>}
      <PageHeadline>{data?.workOrder?.name}</PageHeadline>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Task No</TableCell>
                  <TableCell>Item name</TableCell>
                  <TableCell>Task</TableCell>
                  <TableCell>Completion</TableCell>
                  <TableCell>Inspection</TableCell>
                  <TableCell>Next</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {tasks.map((task) => (
                  <TableRow
                    key={task.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ whiteSpace: "nowrap" }}
                    >
                      {task.index}
                    </TableCell>
                    <TableCell sx={{ minWidth: "8rem" }}>
                      {task.maintainableName}
                    </TableCell>
                    <TableCell sx={{ minWidth: "12rem" }}>
                      {task.taskDescription}
                    </TableCell>
                    <TableCell>
                      <CompletionStatus
                        id={task.id}
                        disabled={Boolean(workOrder?.completedAt)}
                        completedAt={task.completedAt}
                        completedBy={task.completedBy}
                      />
                    </TableCell>
                    <TableCell>
                      <InspectionStatus
                        id={task.id}
                        disabled={Boolean(workOrder?.completedAt)}
                        inspectedAt={task.inspectedAt}
                        inspectedBy={task.inspectedBy}
                      />
                    </TableCell>
                    <TableCell>
                      <OverrideStatus
                        id={task.id}
                        date={task.nextOverride?.date}
                        hours={
                          task.nextOverride?.counters?.find(
                            (item) => item.name === "hours"
                          )?.value
                        }
                        circles={
                          task.nextOverride?.counters?.find(
                            (item) => item.name === "circles"
                          )?.value
                        }
                      />
                    </TableCell>
                    <TableCell
                      sx={{ whiteSpace: "nowrap", textAlign: "right" }}
                    >
                      <IconButton
                        aria-label="export"
                        href={`${config.exportUrl}/tasks/${task.id}`}
                      >
                        <FileDownloadIcon />
                      </IconButton>
                      {!task.instructionId && !task.completedAt && (
                        <>
                          <IconButton
                            component={Link}
                            to={paths.editTask(task.id)}
                            aria-label="edit"
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            aria-label="delete"
                            onClick={() => setItemToDelete(task)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        {workOrder && (
          <Grid item xs={12}>
            {!workOrder.completedAt && (
              <Button
                startIcon={<AddCircle />}
                component={Link}
                to={paths.createWorkOrderTask(id)}
                variant="contained"
                color="secondary"
                sx={{ mr: 2 }}
              >
                Add Task
              </Button>
            )}
            <Button
              startIcon={<AttachFileIcon />}
              onClick={() => setDocsOpened(true)}
              variant="contained"
              color="secondary"
              sx={{ mr: 2 }}
            >
              Documents
            </Button>
            <WorkOrderStatus
              id={id}
              completedAt={workOrder.completedAt}
              completable={completable}
            />
          </Grid>
        )}
      </Grid>
      {itemToDelete && (
        <DeleteConfirmationModal
          open={true}
          deleting={deleting}
          itemName={`${itemToDelete.index}`}
          onCancel={() => setItemToDelete(undefined)}
          onConfirm={() => handleDelete(itemToDelete.id)}
        />
      )}
      <DocumentsModal
        workOrderId={id}
        open={docsOpened}
        disabled={Boolean(workOrder?.completedAt)}
        onCancel={() => setDocsOpened(false)}
      />
    </PageContainer>
  );
}

export default ListWorkOrderTasksPage;
