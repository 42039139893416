import { Chip } from "@mui/material";
import * as React from "react";
import { VehicleStatus as VehicleStatusEnum } from "~_generated/graphql";

export const vehicleStatusNames = {
  [VehicleStatusEnum.Service]: "In service",
  [VehicleStatusEnum.Broken]: "On the ground",
  [VehicleStatusEnum.Maintenance]: "Maintenance",
};

interface Props {
  status: VehicleStatusEnum;
}

function getColor(status: VehicleStatusEnum) {
  switch (status) {
    case VehicleStatusEnum.Service:
      return "success";
    case VehicleStatusEnum.Broken:
      return "error";
    case VehicleStatusEnum.Maintenance:
      return "warning";
  }
}

function VehicleStatus({ status }: Props) {
  return (
    <Chip
      variant="outlined"
      color={getColor(status)}
      label={vehicleStatusNames[status]}
      sx={{ textTransform: "lowercase" }}
    />
  );
}
export default VehicleStatus;
