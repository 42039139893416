import { useQuery } from "@apollo/client";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { SelectProps } from "@mui/material/Select/Select";
import { useMemo } from "react";
import * as React from "react";
import { LIST_MANUALS } from "~routes/Programs/ListProgramsPage";

interface ComponentProps extends SelectProps {
  programId?: string;
  selectedId?: string | null;
  onSelectId: (id: string | null) => void;
}

function ParentProgramSelector({
  programId,
  selectedId,
  onSelectId,
  ...props
}: ComponentProps) {
  const { data } = useQuery(LIST_MANUALS);
  const programs = useMemo(() => {
    const items = data?.programs?.items;
    if (items) {
      return items.filter((item) => item.id !== programId);
    } else {
      return [];
    }
  }, [data, programId]);

  return (
    <Select
      value={selectedId || ""}
      onChange={(event) =>
        onSelectId(event.target.value ? String(event.target.value) : null)
      }
      {...props}
    >
      <MenuItem value="">-No parent-</MenuItem>
      {programs.map((program) => (
        <MenuItem value={program.id} key={program.id}>
          {program.model}: {program.name}
        </MenuItem>
      ))}
    </Select>
  );
}

export default ParentProgramSelector;
