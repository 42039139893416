import * as React from "react";
import { useCallback, useState } from "react";
import { HoursFormat } from "./Preferences.types";
import { PreferencesContext } from "./PreferencesContext";

function saveHoursFormat(value: HoursFormat) {
  localStorage.setItem("hoursFormat", value);
}

function getSavedHoursFormat(): HoursFormat {
  const saved = localStorage.getItem("hoursFormat") as HoursFormat;
  return saved || HoursFormat.H;
}

interface ProviderProps {
  children: React.ReactNode;
}

function zeroPad(number: number) {
  return String(number).padStart(2, "0");
}

export const PreferencesProvider = ({ children }: ProviderProps) => {
  const [hoursFormat, setHoursFormat] = useState<HoursFormat>(() =>
    getSavedHoursFormat()
  );

  const formatHours = useCallback(
    (value: number) => {
      switch (hoursFormat) {
        case HoursFormat.H:
          return String(Math.round(100 * value) / 100);

        case HoursFormat.HM:
          const hours = Math.floor(value);
          const minutes = Math.round((((value - hours) * 60) / 100) * 100);
          return `${hours}:${zeroPad(minutes)}`;
      }
    },
    [hoursFormat]
  );

  const setAndSaveHoursFormat = useCallback(
    (value: HoursFormat) => {
      saveHoursFormat(value);
      setHoursFormat(value);
    },
    [setHoursFormat]
  );

  return (
    <PreferencesContext.Provider
      value={{
        hoursFormat,
        formatHours,
        setHoursFormat: setAndSaveHoursFormat,
      }}
    >
      {children}
    </PreferencesContext.Provider>
  );
};
