import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useCallback, useEffect, useState } from "react";
import * as React from "react";
import { Instruction } from "~_generated/graphql";
import EnvironmentRuleForm from "./EnvironmentRuleForm";
import IntervalRuleForm from "./IntervalRuleForm";

export type RulesEditorData = Pick<
  Instruction,
  "intervalRule" | "parameterizedRule"
>;
interface ComponentProps {
  data?: RulesEditorData;
  onChange: (data?: RulesEditorData) => void;
}

function RulesEditor({ data: initialData, onChange }: ComponentProps) {
  const [openedTab, setOpenedTab] = useState(
    initialData?.parameterizedRule ? 1 : 0
  );
  const [data, setData] = useState(initialData);

  const handleTabChange = (event: React.SyntheticEvent, tabIndex: number) => {
    switch (tabIndex) {
      case 0:
        onChange({ intervalRule: data?.intervalRule });
        break;
      case 1:
        onChange({ parameterizedRule: data?.parameterizedRule });
        break;
    }
    setOpenedTab(tabIndex);
  };

  const handleIntervalRuleChange = useCallback(
    (intervalRule: Instruction["intervalRule"]) => {
      setData((data) => ({
        ...data,
        intervalRule,
      }));
    },
    [setData]
  );

  const handleParameterizedRuleChange = useCallback(
    (parameterizedRule: Instruction["parameterizedRule"]) => {
      setData((data) => ({
        ...data,
        parameterizedRule,
      }));
    },
    [setData]
  );

  useEffect(() => {
    switch (openedTab) {
      case 0:
        onChange({ intervalRule: data?.intervalRule });
        break;
      case 1:
        onChange({ parameterizedRule: data?.parameterizedRule });
        break;
    }
  }, [openedTab, data, onChange]);

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}>
        <Tabs value={openedTab} onChange={handleTabChange}>
          <Tab label="Basic" />
          <Tab label="Environment dependent" />
        </Tabs>
      </Box>
      <div role="tabpanel" hidden={openedTab !== 0}>
        <IntervalRuleForm
          onChange={handleIntervalRuleChange}
          data={data?.intervalRule || undefined}
        />
      </div>
      <div role="tabpanel" hidden={openedTab !== 1}>
        <EnvironmentRuleForm
          onChange={handleParameterizedRuleChange}
          data={data?.parameterizedRule || undefined}
        />
      </div>
    </>
  );
}

export default RulesEditor;
