import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { format } from "date-fns";
import * as React from "react";
import { useState } from "react";
import { Vehicle, VehicleStatus } from "~_generated/graphql";
import { transformCounters } from "~components/components.utils";
import { vehicleStatusNames } from "./VehicleStatus";
import HoursField from "~components/HoursField";

export type VehicleFormData = Pick<
  Vehicle,
  "model" | "serial" | "registrationNumber" | "status"
> & {
  maintainable: Pick<
    Vehicle["maintainable"],
    "counters" | "date" | "parameters"
  >;
};
interface ComponentProps {
  data?: VehicleFormData;
  onSubmit: (data: VehicleFormData) => void;
  children: React.ReactNode;
}

const environments = {
  mild: "Mild Corrosive",
  moderate: "Moderate Corrosive",
  severe: "Severe Corrosive",
};

function VehicleForm({ data, onSubmit, children }: ComponentProps) {
  const [model, setModel] = useState<string>(data?.model || "");
  const [serial, setSerial] = useState<string>(data?.serial || "");
  const [registrationNumber, setRegistrationNumber] = useState<string>(
    data?.registrationNumber || ""
  );
  const [status, setStatus] = useState<string>(data?.status || "");

  const dataHours = data?.maintainable?.counters?.find(
    (item) => item.name === "hours"
  );
  const [hours, setHours] = useState<number>(dataHours ? dataHours.value : 0);
  const dataCircles = data?.maintainable?.counters?.find(
    (item) => item.name === "circles"
  );
  const [circles, setCircles] = useState<string>(
    dataCircles ? String(dataCircles.value) : ""
  );
  const [date, setDate] = useState<Date | null>(
    data?.maintainable.date ? new Date(data?.maintainable.date) : null
  );
  const [environment, setEnvironment] = useState<string>(
    data?.maintainable?.parameters?.environment || "moderate"
  );

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit({
      model,
      serial,
      registrationNumber,
      status: status as VehicleStatus,
      maintainable: {
        counters: transformCounters(["hours", "circles"], [hours, circles]),
        date: date ? format(date, "yyyy-MM-dd") : undefined,
        parameters: { environment },
      },
    });
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            label="Model"
            value={model}
            onChange={(event) => setModel(event.currentTarget.value)}
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            label="Serial"
            value={serial}
            onChange={(event) => setSerial(event.currentTarget.value)}
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            label="Registration Number"
            value={registrationNumber}
            onChange={(event) =>
              setRegistrationNumber(event.currentTarget.value)
            }
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel id="status">Status</InputLabel>
            <Select
              required
              labelId="status"
              value={status}
              onChange={(event) => setStatus(event.target.value)}
              label="Status"
              variant="standard"
            >
              {Object.entries(vehicleStatusNames).map(([value, title]) => (
                <MenuItem value={value} key={value}>
                  {title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <HoursField
            required
            label="Flight hours"
            value={hours}
            onChange={(value) => setHours(value || 0)}
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            type="number"
            label="Flight circles"
            value={circles}
            onChange={(event) => setCircles(event.currentTarget.value)}
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePicker
            label="Manufacture date"
            value={date}
            onChange={(value) => setDate(value)}
            renderInput={(params) => (
              <TextField required {...params} variant="standard" fullWidth />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel id="environment">Environment</InputLabel>
            <Select
              required
              labelId="environment"
              value={environment}
              onChange={(event) => setEnvironment(event.target.value)}
              label="Environment"
              variant="standard"
            >
              {Object.entries(environments).map(([value, name]) => (
                <MenuItem value={value} key={value}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    </Box>
  );
}

export default VehicleForm;
