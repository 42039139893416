import { useMutation } from "@apollo/client";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import { gql } from "~_generated/gql";
import { PageContainer, PageHeadline } from "~layout";
import paths from "~routes/paths";
import { LIST_VEHICLES } from "./ListVehiclesPage";
import VehicleForm, { VehicleFormData } from "./VehicleForm";

export const CREATE_VEHICLE = gql(`
  mutation CreateVehicle($data: CreateVehicleInput!) {
    createVehicle(data: $data) {
      id
    }
  }`);

function CreateVehiclePage() {
  const [create, { loading, error }] = useMutation(CREATE_VEHICLE);
  const navigate = useNavigate();

  const handleSubmit = (data: VehicleFormData) => {
    create({ variables: { data }, refetchQueries: [LIST_VEHICLES] }).then(() =>
      navigate(paths.listVehicles)
    );
  };

  return (
    <PageContainer>
      <PageHeadline>Creating new Vehicle</PageHeadline>
      {error && <Alert severity="error">{error?.message}</Alert>}
      <VehicleForm onSubmit={handleSubmit}>
        <Button
          component={Link}
          to={paths.listVehicles}
          variant="contained"
          color="secondary"
          sx={{ mr: 1 }}
        >
          Back
        </Button>
        <Button type="submit" variant="contained" disabled={loading}>
          Create
        </Button>
      </VehicleForm>
    </PageContainer>
  );
}

export default CreateVehiclePage;
