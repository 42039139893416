import { gql } from "~_generated/gql";

export const LIST_COMPONENTS = gql(`
  query ListComponents($filter: ComponentsFilter, $pagination: Pagination) {
    components(filter: $filter, pagination: $pagination) {
      items {
        id
        vehicleId
        parentId
        isContainer
        ataNumber
        index
        position
        name
        partNumber
        serialNumber
        keyword
        isInstalled
        maintainableId
        maintainable {
          id
          date
          counters {
            name
            value
          }
          relativeToId
          relativeCounters {
            name
            value
          }
          programIds
        }
      }
      total
    }
  }
`);

export const GET_COMPONENT = gql(`
  query Component($id: ID!) {
    component(id: $id) {
      id
      vehicleId
      parentId
      isContainer
      ataNumber
      index
      name
      position
      partNumber
      serialNumber
      keyword
      isInstalled
      maintainableId
      maintainable {
        id
        date
        counters {
          name
          value
        }
        relativeToId
        relativeCounters {
          name
          value
        }
        programIds
      }
    }
  }
`);

export const DELETE_COMPONENT = gql(`
  mutation DeleteComponent($id: ID!) {
    deleteComponent(id: $id)
  }
`);
