import Button from "@mui/material/Button";
import { ChangeEvent, useState } from "react";
import * as React from "react";
import config from "~config";
import { UploadErrorReport } from "./UploadErrors";

interface Props {
  vehicleId: string;
  maintainableId: string;
  children: React.ReactNode;
  dateFormat: string;
  onSuccess?: (errors: UploadErrorReport) => void;
}

function upload(
  file: File,
  vehicleId: string,
  maintainableId: string,
  dateFormat: string
) {
  let formData = new FormData();
  formData.append("dateFormat", dateFormat);
  formData.append("vehicleId", vehicleId);
  formData.append("maintainableId", maintainableId);
  formData.append("file", file);
  return fetch(`${config.uploadUrl}/components`, {
    method: "POST",
    body: formData,
  });
}

function UploadButton({
  vehicleId,
  maintainableId,
  children,
  onSuccess,
  dateFormat,
}: Props) {
  const [uploadKey, setUploadKey] = useState<number>(Math.random());
  const [loading, setLoading] = useState(false);
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setLoading(true);
      upload(e.target.files[0], vehicleId, maintainableId, dateFormat)
        .then((response) => response.json())
        .then((data) => onSuccess && onSuccess(data.errors))
        .catch((err) => console.error(err))
        .finally(() => setLoading(false));
      setUploadKey(Math.random());
    }
  };

  return (
    <Button
      variant="contained"
      color="secondary"
      component="label"
      disabled={loading}
    >
      {children}
      <input
        type="file"
        accept="text/csv"
        onChange={handleFileChange}
        hidden
        key={uploadKey}
      />
    </Button>
  );
}
export default UploadButton;
