import { useQuery } from "@apollo/client";
import Grid from "@mui/material/Grid/Grid";
import * as React from "react";
import { useParams } from "react-router-dom";
import { GET_COMPONENT } from "~api/components";
import { PageContainer, PageHeadline } from "~layout";
import ComponentsList from "./ComponentsList";

function ListChildrenComponentsPage() {
  const params = useParams();
  const id = params.id || "";
  const { data, error, loading } = useQuery(GET_COMPONENT, {
    variables: { id },
  });
  const component = data?.component;

  return (
    <PageContainer loading={loading} error={error?.message}>
      <PageHeadline>
        {component?.ataNumber}: {component?.index} / {component?.name}
      </PageHeadline>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {component && (
            <ComponentsList vehicleId={component?.vehicleId} parentId={id} />
          )}
        </Grid>
      </Grid>
    </PageContainer>
  );
}

export default ListChildrenComponentsPage;
