import { useQuery } from "@apollo/client";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { SelectProps } from "@mui/material/Select/Select";
import * as React from "react";
import { useMemo } from "react";
import { LIST_COMPONENTS } from "~api/components";

interface ComponentProps extends SelectProps {
  vehicleId: string;
  componentId?: string;
  selectedId?: string | null;
  onSelectId: (id: string | null) => void;
}

function ParentComponentSelector({
  vehicleId,
  componentId,
  selectedId,
  onSelectId,
  ...props
}: ComponentProps) {
  const { data } = useQuery(LIST_COMPONENTS, {
    variables: { filter: { vehicleId, isContainer: true } },
  });
  const components = useMemo(() => {
    const items = data?.components?.items;
    if (items) {
      return items.filter((item) => item.id !== componentId);
    } else {
      return [];
    }
  }, [data, componentId]);

  return (
    <Select
      value={selectedId || ""}
      onChange={(event) =>
        onSelectId(event.target.value ? String(event.target.value) : null)
      }
      {...props}
    >
      <MenuItem value="">-No parent-</MenuItem>
      {components.map((component) => (
        <MenuItem value={component.id} key={component.id}>
          {component.index}: {component.name}
        </MenuItem>
      ))}
    </Select>
  );
}

export default ParentComponentSelector;
