export type Increments<Counter extends string = string> = Record<
  string,
  Partial<Record<Counter, number | null>>
>;

/**
 * Sets counter value for increments record with id and all records where it is a parent
 * It allows us update all child records in one go
 */
export function calculateIncrements<Counter extends string = string>(
  increments: Increments<Counter>,
  tree: Array<{ id: string; relativeToId?: string }>,
  counter: Counter,
  id: string,
  value: number | null
) {
  const toIncrement = [id].concat(getChildrenIds(id, tree));
  const updatedIncrementsTuple = toIncrement.map((id) => [
    id,
    {
      ...increments[id],
      [counter]: value,
    },
  ]);
  const updatedIncrements = Object.fromEntries(updatedIncrementsTuple);
  return {
    ...increments,
    ...updatedIncrements,
  };
}

/**
 * Adds all increments to maintainable counters
 * Only includes items with changed values
 */
export function incrementsToUpdates<Counter extends string = string>(
  increments: Increments<Counter>,
  maintainables: Array<{
    id: string;
    counters: Partial<Record<Counter, number | null>>;
  }>
): Array<{ id: string; counters: Array<{ name: Counter; value: number }> }> {
  return maintainables
    .map(({ id, counters: oldCounters }) => {
      let counters: Array<{ name: Counter; value: number }> = [];
      let hasIncrement = false;
      Object.entries(oldCounters).forEach(([nameStr, oldValue]) => {
        const name = nameStr as Counter;
        const increment =
          (increments && increments[id] && increments[id][name]) || 0;
        if (increment) {
          hasIncrement = true;
        }
        counters.push({ name, value: Number(oldValue) + Number(increment) });
      });
      return { id, counters, hasIncrement };
    })
    .filter(({ hasIncrement }) => hasIncrement)
    .map(({ id, counters }) => ({ id, counters }));
}

/**
 * Collects all the children for id
 */
function getChildrenIds(
  id: string,
  tree: Array<{ id: string; relativeToId?: string }>
): Array<string> {
  return tree
    .filter((item) => item.relativeToId === id)
    .map((item) => item.id)
    .map((id) => [id].concat(getChildrenIds(id, tree)))
    .flat();
}
