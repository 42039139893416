import { useMutation } from "@apollo/client";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import format from "date-fns/format";
import * as React from "react";
import { useState } from "react";
import { gql } from "~_generated/gql";
import { locale } from "~config";
import EditStatusModal from "./EditStatusModal";
import { LIST_WORK_ORDER_TASKS } from "./ListWorkOrderTasksPage";

export const UPDATE_TASK_COMPLETION = gql(`
  mutation UpdateTaskCompletion($id: ID!, $data: UpdateTaskInput!) {
    updateTask(id: $id, data: $data) {
      id
      completedAt
      completedBy
    }
  }
`);

interface Props {
  id: string;
  completedAt?: Date;
  completedBy?: string | null;
  disabled: boolean;
}

function CompletionStatus({ id, completedAt, completedBy, disabled }: Props) {
  const [open, setOpen] = useState<boolean>(false);
  const completed = completedAt && completedBy;
  const [update, { loading, error }] = useMutation(UPDATE_TASK_COMPLETION);

  const handleConfirm = (date: Date | null, name: string | null) => {
    update({
      variables: { id, data: { completedAt: date, completedBy: name } },
      refetchQueries: [LIST_WORK_ORDER_TASKS],
    }).then(() => setOpen(false));
  };

  return (
    <>
      {completed ? (
        <Tooltip
          title={`Completed by ${completedBy} ${format(
            new Date(completedAt),
            "P",
            {
              locale,
            }
          )}`}
        >
          <IconButton aria-label="edit" onClick={() => setOpen(true)}>
            <CheckIcon color="success" />
          </IconButton>
        </Tooltip>
      ) : (
        <IconButton aria-label="add" onClick={() => setOpen(true)}>
          <AddIcon />
        </IconButton>
      )}
      <EditStatusModal
        title="Completion status"
        loading={loading}
        error={error?.message}
        disabled={disabled}
        open={open}
        name={completedBy || undefined}
        date={completedAt}
        onCancel={() => setOpen(false)}
        onConfirm={handleConfirm}
      />
    </>
  );
}

export default CompletionStatus;
