import { useMutation, useQuery } from "@apollo/client";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { gql } from "~_generated/gql";
import { GET_VEHICLE } from "~api/vehicles";
import ProgramsSelector from "~components/ProgramsSelector";
import { PageContainer, PageHeadline } from "~layout";
import paths from "~routes/paths";
import { LIST_VEHICLES } from "./ListVehiclesPage";
import VehicleForm, { VehicleFormData } from "./VehicleForm";

export const UPDATE_VEHICLE = gql(`
  mutation UpdateVehicle($id: ID!, $data: UpdateVehicleInput!) {
    updateVehicle(id: $id, data: $data) {
      id
      model
      serial
      registrationNumber
      status
      maintainable {
        date
        counters {
          name
          value
        }
        programIds
      }
    }
  }`);

function EditVehiclePage() {
  const params = useParams();
  const navigate = useNavigate();
  const id = params.id || "";
  const { data, error, loading } = useQuery(GET_VEHICLE, {
    variables: { vehicleId: id },
  });
  const [
    update,
    { data: updateData, loading: updateLoading, error: updateError },
  ] = useMutation(UPDATE_VEHICLE);
  const [programIds, setProgramIds] = useState<Array<string>>([]);

  useEffect(() => {
    if (updateData) {
      navigate(paths.listVehicles);
    }
  }, [navigate, updateData]);

  useEffect(() => {
    if (data) {
      setProgramIds(data.vehicle?.maintainable.programIds || []);
    }
  }, [data]);

  const handleSubmit = (formData: VehicleFormData) => {
    const data = {
      ...formData,
      maintainable: {
        ...formData.maintainable,
        programIds,
      },
    };
    update({ variables: { id, data }, refetchQueries: [LIST_VEHICLES] });
  };

  return (
    <PageContainer loading={loading} error={error?.message}>
      <PageHeadline>
        Update {data?.vehicle?.model}/{data?.vehicle?.serial}
      </PageHeadline>
      {updateError && <Alert severity="error">{updateError?.message}</Alert>}
      {data?.vehicle && (
        <VehicleForm data={data.vehicle} onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography>Linked programs</Typography>
              <ProgramsSelector
                selection={programIds}
                setSelection={setProgramIds}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                component={Link}
                to={paths.listVehicles}
                variant="contained"
                color="secondary"
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Button
                type="submit"
                variant="contained"
                disabled={updateLoading}
              >
                Save changes
              </Button>
            </Grid>
          </Grid>
        </VehicleForm>
      )}
    </PageContainer>
  );
}

export default EditVehiclePage;
