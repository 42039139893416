import { useQuery } from "@apollo/client";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { useState } from "react";
import { gql } from "~_generated/gql";
import config from "~config";
import UploadDocumentButton from "~routes/WorkOrders/DocumentsModal/UploadDocumentButton";

export const GET_WORK_ORDER_DOCUMENTS = gql(`
  query GetWorkOrderDocuments($id: ID!) {
    workOrder(id: $id) {
      id
      documents {
        name
        url
      }
    }
  }
`);

function deleteDocument(workOrderId: string, fileName: string) {
  return fetch(
    `${config.uploadUrl}/work-orders/${workOrderId}/documents/${fileName}`,
    {
      method: "POST",
    }
  );
}

interface Props {
  workOrderId: string;
  open: boolean;
  onCancel: () => void;
  disabled: boolean;
}

function DocumentsModal({ workOrderId, open, disabled, onCancel }: Props) {
  const {
    data,
    error: fetchError,
    refetch,
  } = useQuery(GET_WORK_ORDER_DOCUMENTS, {
    variables: { id: workOrderId },
    fetchPolicy: "network-only",
  });

  const [uploadError, setUploadError] = useState<Error>();
  const [deleteError, setDeleteError] = useState<Error>();

  const documents = data?.workOrder?.documents || [];
  const handleDelete = (name: string) => {
    console.log("DELETE!", name);
    setUploadError(undefined);
    setDeleteError(undefined);
    deleteDocument(workOrderId, name)
      .then(() => refetch())
      .catch(() => setDeleteError(new Error("Cannot delete file")));
  };

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Documents</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <TableContainer>
            <Table sx={{ minWidth: "20rem" }}>
              <TableBody>
                {documents.map((document) => (
                  <TableRow key={document.url}>
                    <TableCell component="th" scope="row">
                      {document.name}
                    </TableCell>
                    <TableCell
                      sx={{ whiteSpace: "nowrap", textAlign: "right" }}
                    >
                      <IconButton aria-label="export" href={document.url}>
                        <FileDownloadIcon />
                      </IconButton>
                      {!disabled && (
                        <IconButton
                          aria-label="delete"
                          onClick={() => handleDelete(document.name)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {fetchError && <Alert severity="error">{fetchError.message}</Alert>}
          {uploadError && <Alert severity="error">{uploadError.message}</Alert>}
          {deleteError && <Alert severity="error">{deleteError.message}</Alert>}
          {!disabled && (
            <Box>
              <UploadDocumentButton
                workOrderId={workOrderId}
                onSuccess={() => refetch()}
                onError={(error) => setUploadError(error)}
              />
            </Box>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="info">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default DocumentsModal;
