import { useMutation, useQuery } from "@apollo/client";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import * as React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { gql } from "~_generated/gql";
import { PageContainer, PageHeadline } from "~layout";
import paths from "~routes/paths";
import { LIST_USERS } from "./ListUsersPage";
import UserForm, { UserFormData } from "./UserForm";

export const GET_USER = gql(`
  query User($id: ID!) {
    user(id: $id) {
      id
      username
      lastName
      firstName
      isAdmin
    }
  }`);

export const UPDATE_USER = gql(`
  mutation UpdateUser($id: ID!, $data: UpdateUserInput!) {
    updateUser(id: $id, data: $data) {
      id
    }
  }`);

function EditUserPage() {
  const params = useParams();
  const navigate = useNavigate();
  const id = params.id || "";
  const { data, error, loading } = useQuery(GET_USER, {
    variables: { id },
  });
  const [update, { loading: updateLoading, error: updateError }] =
    useMutation(UPDATE_USER);

  const handleSubmit = (data: UserFormData) => {
    update({ variables: { id, data }, refetchQueries: [LIST_USERS] }).then(
      () => {
        navigate(paths.admin.listUsers);
      }
    );
  };

  return (
    <PageContainer loading={loading} error={error?.message}>
      <PageHeadline>Update {data?.user?.username}</PageHeadline>
      {updateError && <Alert severity="error">{updateError?.message}</Alert>}
      {data?.user && (
        <UserForm data={data.user} onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Button
                component={Link}
                to={paths.admin.listUsers}
                variant="contained"
                color="secondary"
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Button
                type="submit"
                variant="contained"
                disabled={updateLoading}
              >
                Save changes
              </Button>
            </Grid>
          </Grid>
        </UserForm>
      )}
    </PageContainer>
  );
}

export default EditUserPage;
