import { useMutation, useQuery } from "@apollo/client";
import AddCircle from "@mui/icons-material/AddCircle";
import CalculatorIcon from "@mui/icons-material/Calculate";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ListAltIcon from "@mui/icons-material/ListAlt";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid/Grid";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import format from "date-fns/format";
import * as React from "react";
import { Link } from "react-router-dom";
import { gql } from "~_generated/gql";
import CounterValue from "~components/CounterValue";
import { DeleteConfirmationModal } from "~components/modals";
import { locale } from "~config";
import { PageContainer } from "~layout";
import paths from "~routes/paths";
import CountersModal from "./CountersModal";
import NextMaintenanceContainer from "./NextMaintenanceContainer";
import VehicleStatus from "./VehicleStatus";

export const LIST_VEHICLES = gql(`
  query Vehicles {
    vehicles {
      items {
        id
        model
        serial
        registrationNumber
        status
        maintainableId
        maintainable {
          id
          date
          counters {
            name
            value
          }
          parameters {
            environment
          }
        }
      }
      total
    }
  }
`);

export const DELETE_VEHICLE = gql(`
  mutation DeleteVehicle($deleteVehicleId: ID!) {
    deleteVehicle(id: $deleteVehicleId)
  }`);

function ListVehiclesPage() {
  const { data, error, loading } = useQuery(LIST_VEHICLES);
  const [deleteVehicle, { loading: deleting, error: deleteError }] =
    useMutation(DELETE_VEHICLE);
  const [itemToDelete, setItemToDelete] = React.useState<{
    id: string;
    model: string;
    serial: string;
  }>();
  const [itemToIncrement, setItemToIncrement] = React.useState<{
    id: string;
    model: string;
    serial: string;
  }>();

  const handleDelete = (id: string) => {
    deleteVehicle({
      variables: { deleteVehicleId: id },
      refetchQueries: [LIST_VEHICLES],
    }).finally(() => setItemToDelete(undefined));
  };

  return (
    <PageContainer loading={loading} error={error?.message}>
      {deleteError && <Alert severity="error">{deleteError?.message}</Alert>}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Model</TableCell>
                  <TableCell>Serial</TableCell>
                  <TableCell>Reg No</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Flight hours</TableCell>
                  <TableCell>Flight circles</TableCell>
                  <TableCell>Manufactured at</TableCell>
                  <TableCell>Maintenance</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.vehicles?.items?.map((vehicle) => (
                  <TableRow
                    key={vehicle.serial}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ minWidth: "8rem" }}
                    >
                      {vehicle.model}
                    </TableCell>
                    <TableCell sx={{ minWidth: "8rem" }}>
                      {vehicle.serial}
                    </TableCell>
                    <TableCell sx={{ minWidth: "8rem" }}>
                      {vehicle.registrationNumber}
                    </TableCell>
                    <TableCell>
                      <VehicleStatus status={vehicle.status} />
                    </TableCell>
                    <TableCell>
                      <CounterValue
                        name="hours"
                        counters={vehicle.maintainable?.counters}
                      />
                    </TableCell>
                    <TableCell>
                      <CounterValue
                        name="circles"
                        counters={vehicle.maintainable?.counters}
                      />
                    </TableCell>
                    <TableCell>
                      {format(new Date(vehicle.maintainable.date), "P", {
                        locale,
                      })}
                    </TableCell>
                    <TableCell sx={{ minWidth: "18rem" }}>
                      <NextMaintenanceContainer id={vehicle.id} />
                    </TableCell>
                    <TableCell
                      sx={{ whiteSpace: "nowrap", textAlign: "right" }}
                    >
                      <IconButton
                        aria-label="delete"
                        onClick={() => setItemToIncrement(vehicle)}
                      >
                        <MoreTimeIcon />
                      </IconButton>
                      <IconButton
                        component={Link}
                        to={paths.viewVehicleStatus(
                          vehicle.id,
                          vehicle.maintainableId
                        )}
                        aria-label="create work order"
                      >
                        <CalculatorIcon />
                      </IconButton>
                      <IconButton
                        component={Link}
                        to={paths.listVehicleComponents(vehicle.id)}
                        aria-label="view components"
                      >
                        <ListAltIcon />
                      </IconButton>
                      <IconButton
                        component={Link}
                        to={paths.editVehicle(vehicle.id)}
                        aria-label="edit"
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        onClick={() => setItemToDelete(vehicle)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12}>
          <Button
            startIcon={<AddCircle />}
            component={Link}
            to={paths.createVehicle}
            variant="contained"
            color="secondary"
          >
            Add Vehicle
          </Button>
        </Grid>
      </Grid>
      {itemToDelete && (
        <DeleteConfirmationModal
          open={true}
          deleting={deleting}
          itemName={`${itemToDelete.model} (${itemToDelete.serial})`}
          onCancel={() => setItemToDelete(undefined)}
          onConfirm={() => handleDelete(itemToDelete.id)}
        />
      )}
      {itemToIncrement && (
        <CountersModal
          open={true}
          vehicleId={itemToIncrement.id}
          onCancel={() => setItemToIncrement(undefined)}
        />
      )}
    </PageContainer>
  );
}

export default ListVehiclesPage;
