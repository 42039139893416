import { styled } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

export const Container = styled("div")`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;
export const NavButtonContainer = styled(Box)`
  flex-grow: 1;
  display: flex;
`;
export const NavButton = styled<any>(Button)(
  ({ theme }) => `
  color: white;
  margin-top: ${theme.spacing(2)};
  margin-bottom: ${theme.spacing(2)};
  text-align: center;
`
);
