import { CircularProgress, styled } from "@mui/material";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import * as React from "react";

const Placeholder = styled(Box)`
  min-height: 10rem;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface Props {
  loading?: boolean;
  error?: React.ReactNode;
  children: React.ReactNode;
}

function Loadable({ loading, error, children }: Props) {
  if (loading) {
    return (
      <Placeholder>
        <CircularProgress />
      </Placeholder>
    );
  } else if (error) {
    return (
      <Placeholder>
        <Alert severity="error">{error}</Alert>
      </Placeholder>
    );
  } else {
    return <>{children}</>;
  }
}
export default Loadable;
