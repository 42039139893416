import { useMutation } from "@apollo/client";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import * as React from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { gql } from "~_generated/gql";
import { PageContainer, PageHeadline } from "~layout";
import paths from "~routes/paths";
import { LIST_MANUALS } from "./ListProgramsPage";
import ProgramForm, { ProgramFormData } from "./ProgramForm";

export const CREATE_MANUAL = gql(`
  mutation CreateProgram($data: CreateProgramInput!) {
    createProgram(data: $data) {
      model
      name
    }
  }`);

function CreateProgramPage() {
  const [create, { data, loading, error }] = useMutation(CREATE_MANUAL);
  const navigate = useNavigate();

  const handleSubmit = (data: ProgramFormData) => {
    const variables = {
      data,
    };
    create({ variables, refetchQueries: [LIST_MANUALS] });
  };

  useEffect(() => {
    if (data) {
      navigate(paths.listPrograms);
    }
  }, [data, navigate]);

  return (
    <PageContainer>
      <PageHeadline>Creating new Program</PageHeadline>
      {error && <Alert severity="error">{error?.message}</Alert>}
      <ProgramForm onSubmit={handleSubmit}>
        <Button
          component={Link}
          to={paths.listPrograms}
          variant="contained"
          color="secondary"
          sx={{ mr: 1 }}
        >
          Back
        </Button>
        <Button type="submit" variant="contained" disabled={loading}>
          Create
        </Button>
      </ProgramForm>
    </PageContainer>
  );
}

export default CreateProgramPage;
