import { useMutation, useQuery } from "@apollo/client";
import RefreshIcon from "@mui/icons-material/Refresh";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid/Grid";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import * as React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { gql } from "~_generated/gql";
import { LIST_COMPONENTS } from "~api/components";
import { PageContainer, PageHeadline } from "~layout";
import UploadErrors, { UploadErrorReport } from "./UploadErrors";
import UploadButton from "./UploadButton";
import UploadPreview from "./UploadPreview";
import paths from "~routes/paths";

export const LIST_TEMPORARY_COMPONENTS = gql(`
  query VehicleTemporaryComponents($id: ID!) {
    vehicle(id: $id) {
      id
      model
      serial
      components(filter: {isTemporary: true}) {
        items {
          id
          ataNumber
          index
          position
          name
          partNumber
          serialNumber
          keyword
          isInstalled
          maintainable {
            id
            date
            counters {
              name
              value
            }
            relativeToId
            relativeCounters {
              name
              value
            }
            programIds
          }
        }
        total
      }
    }
    
  }
`);

export const SAVE_TEMPORARY_COMPONENTS = gql(`
  mutation SaveTemporaryComponents($id: ID!) {
    saveTemporaryComponents(vehicleId: $id)
  }`);
export const DELETE_TEMPORARY_COMPONENTS = gql(`
  mutation DeleteTemporaryComponents($id: ID!) {
    deleteTemporaryComponents(vehicleId: $id)
  }`);

function UploadComponentsPage() {
  const navigate = useNavigate();
  const params = useParams();
  const vehicleId = params.id || "";
  const maintainableId = params.maintainableId || "";

  const [dateFormat, setDateFormat] = useState("dd/mm/yyyy");
  const [uploadErrors, setUploadErrors] = useState<UploadErrorReport>();

  const { data, error, loading, refetch } = useQuery(
    LIST_TEMPORARY_COMPONENTS,
    {
      variables: { id: vehicleId },
    }
  );
  const [save, { loading: saving, error: saveError }] = useMutation(
    SAVE_TEMPORARY_COMPONENTS
  );
  const [cancel, { loading: canceling, error: cancelError }] = useMutation(
    DELETE_TEMPORARY_COMPONENTS
  );

  const handleConfirm = () => {
    save({
      variables: { id: vehicleId },
      refetchQueries: [LIST_COMPONENTS, LIST_TEMPORARY_COMPONENTS],
    }).then(() => navigate(paths.listVehicleComponents(vehicleId)));
  };
  const handleCancel = () => {
    cancel({
      variables: { id: vehicleId },
      refetchQueries: [LIST_TEMPORARY_COMPONENTS],
    });
  };

  return (
    <PageContainer loading={loading} error={error?.message}>
      <PageHeadline>
        {data?.vehicle?.model} / {data?.vehicle?.serial}
      </PageHeadline>
      {saveError && <Alert severity="error">{saveError?.message}</Alert>}
      {cancelError && <Alert severity="error">{cancelError?.message}</Alert>}
      {data?.vehicle?.components?.items.length ? (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Button
              onClick={handleCancel}
              variant="contained"
              color="secondary"
              disabled={canceling}
              sx={{ mr: 2 }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleConfirm}
              variant="contained"
              color="secondary"
              disabled={saving}
            >
              Confirm
            </Button>
            <IconButton onClick={() => refetch()}>
              <RefreshIcon />
            </IconButton>
          </Grid>
          {uploadErrors && (
            <Grid item xs={12}>
              <Typography>Errors</Typography>
              <UploadErrors errors={uploadErrors} />
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography>Upload preview</Typography>
            <UploadPreview components={data.vehicle.components.items} />
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              required
              label="Date format"
              value={dateFormat}
              onChange={(event) => setDateFormat(event.currentTarget.value)}
              fullWidth
              variant="standard"
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              component={Link}
              to={paths.listVehicleComponents(vehicleId)}
              variant="contained"
              color="secondary"
              disabled={canceling}
              sx={{ mr: 2 }}
            >
              Back to Vehicle
            </Button>
            <UploadButton
              vehicleId={vehicleId}
              maintainableId={maintainableId}
              dateFormat={dateFormat}
              onSuccess={(errors) => {
                console.log(errors);
                setUploadErrors(errors);
                refetch();
              }}
            >
              Upload
            </UploadButton>
            <IconButton onClick={() => refetch()}>
              <RefreshIcon />
            </IconButton>
          </Grid>
        </Grid>
      )}
    </PageContainer>
  );
}

export default UploadComponentsPage;
