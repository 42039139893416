import { useMutation } from "@apollo/client";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid/Grid";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { gql } from "~_generated/gql";
import paths from "~routes/paths";
import { LIST_WORK_ORDER_TASKS } from "~routes/Tasks/ListWorkOrderTasksPage";

export const CREATE_WORK_ORDER = gql(`
  mutation createWorkOrder($data: CreateWorkOrderInput!) {
    createWorkOrder(data: $data) {
      id
      name
    }
  }
`);
export interface TaskInput {
  instructionId: string;
  instructionModificationIds?: Array<string> | null;
}

interface Props {
  tasks: Array<TaskInput>;
  maintainableId: string;
}

function WorkOrderCreator({ maintainableId, tasks }: Props) {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [create, { loading, error, data }] = useMutation(CREATE_WORK_ORDER);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    create({
      variables: { data: { name, tasks, maintainableId } },
      refetchQueries: [LIST_WORK_ORDER_TASKS],
    });
  };

  useEffect(() => {
    if (data?.createWorkOrder?.id) {
      navigate(paths.listWorkOrderTasks(data?.createWorkOrder?.id));
    }
  }, [data, navigate]);

  return (
    <Stack>
      <Grid container spacing={3} component="form" onSubmit={handleSubmit}>
        {error && (
          <Grid item xs={12}>
            <Alert severity="error">{error?.message}</Alert>
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <TextField
            required
            label="Work order name"
            value={name}
            onChange={(event) => setName(event.currentTarget.value)}
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            disabled={loading || !tasks.length}
          >
            Create work order
          </Button>
        </Grid>
      </Grid>
    </Stack>
  );
}

export default WorkOrderCreator;
