import { CircularProgress, styled } from "@mui/material";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import * as React from "react";

const PageContainerStyled = styled("main")(
  ({ theme }) => `
  padding: ${theme.spacing(2)};
`
);
const PagePlaceholder = styled(Box)`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;
interface PageContainerProps {
  loading?: boolean;
  error?: React.ReactNode;
  children: React.ReactNode;
}

function PageContainer({ loading, error, children }: PageContainerProps) {
  if (loading) {
    return (
      <PagePlaceholder>
        <CircularProgress />
      </PagePlaceholder>
    );
  } else if (error) {
    return (
      <PagePlaceholder>
        <Alert severity="error">{error}</Alert>
      </PagePlaceholder>
    );
  } else {
    return <PageContainerStyled>{children}</PageContainerStyled>;
  }
}
export default PageContainer;
