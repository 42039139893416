import { ApolloProvider } from "@apollo/client";
import { createTheme, ThemeProvider, useMediaQuery } from "@mui/material";
import { amber, deepPurple } from "@mui/material/colors";
import CssBaseline from "@mui/material/CssBaseline";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React from "react";
import { RouterProvider } from "react-router-dom";
import client from "~api/client";
import { locale } from "~config";
import routes from "~routes/routes";
import { PreferencesProvider } from "~preferences";

function App() {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? "dark" : "light",
          primary: deepPurple,
          secondary: amber,
        },
      }),
    [prefersDarkMode]
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ApolloProvider client={client}>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={locale}
        >
          <PreferencesProvider>
            <RouterProvider router={routes} />
          </PreferencesProvider>
        </LocalizationProvider>
      </ApolloProvider>
    </ThemeProvider>
  );
}

export default App;
