import { useQuery } from "@apollo/client";
import { CircularProgress } from "@mui/material";
import Alert from "@mui/material/Alert";
import * as React from "react";
import { VEHICLE_NEXT_MAINTENANCE } from "~api/vehicles";
import NextMaintenanceReport from "~routes/Vehicles/NextMaintenanceReport";

interface Props {
  id: string;
}

function NextMaintenanceContainer({ id }: Props) {
  const { data, error, loading } = useQuery(VEHICLE_NEXT_MAINTENANCE, {
    variables: { id },
    fetchPolicy: "cache-and-network",
  });
  const next = data?.vehicle?.nextMaintenance;

  if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }
  if (next) {
    return (
      <NextMaintenanceReport
        date={next.date && new Date(next.date)}
        hours={next.hours}
        circles={next.circles}
      />
    );
  }
  if (loading) {
    return <CircularProgress color="inherit" size="1rem" />;
  }
  return null;
}

export default NextMaintenanceContainer;
