import { AccountCircle } from "@mui/icons-material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";
import * as React from "react";
import { Link, Outlet, useLoaderData, useNavigate } from "react-router-dom";
import { logout } from "~api/auth";
import paths from "~routes/paths";
import * as Styled from "./RootLayout.styles";

function RootLayout() {
  const navigate = useNavigate();
  const user = useLoaderData() as { id: string; isAdmin?: boolean };
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    navigate(paths.auth);
  };

  return (
    <Styled.Container>
      <AppBar position="sticky">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Styled.NavButtonContainer>
              <Styled.NavButton component={Link} to={paths.listVehicles}>
                Vehicles
              </Styled.NavButton>
              <Styled.NavButton component={Link} to={paths.listPrograms}>
                Maintenance programs
              </Styled.NavButton>
              <Styled.NavButton component={Link} to={paths.listWorkOrders}>
                Work orders
              </Styled.NavButton>
            </Styled.NavButtonContainer>

            <Box sx={{ flexGrow: 0 }}>
              <div>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  title={user.id}
                  onClick={handleMenu}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleCloseMenu}
                >
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                  <MenuItem
                    onClick={handleCloseMenu}
                    component={Link}
                    to={paths.profilePreferences}
                  >
                    Preferences
                  </MenuItem>
                </Menu>
              </div>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Outlet />
    </Styled.Container>
  );
}

export default RootLayout;
