import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useEffect, useState } from "react";
import { ParameterizedRule } from "~_generated/graphql";
import IntervalRuleForm, {
  IntervalRuleData,
} from "~routes/Instructions/RulesEditor/IntervalRuleForm";

export type ParameterizedRuleData = ParameterizedRule;
interface ComponentProps {
  data?: ParameterizedRuleData;
  onChange: (data: ParameterizedRuleData) => void;
}

function EnvironmentRuleForm({ data, onChange }: ComponentProps) {
  const [mildData, setMildData] = useState<IntervalRuleData>(() => {
    const rule = data?.rules?.find((rule) => rule?.value === "mild");
    if (rule) {
      return rule;
    } else {
      return {};
    }
  });
  const [moderateData, setModerateData] = useState<IntervalRuleData>(() => {
    const rule = data?.rules?.find((rule) => rule?.value === "moderate");
    if (rule) {
      return rule;
    } else {
      return {};
    }
  });
  const [severeData, setSevereData] = useState<IntervalRuleData>(() => {
    const rule = data?.rules?.find((rule) => rule?.value === "severe");
    if (rule) {
      return rule;
    } else {
      return {};
    }
  });

  useEffect(() => {
    onChange({
      parameter: "environment",
      rules: [
        { ...mildData, value: "mild" },
        { ...moderateData, value: "moderate" },
        { ...severeData, value: "severe" },
      ],
    });
  }, [mildData, moderateData, severeData, onChange]);

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Typography sx={{ mb: 1 }}>Mild corrosive environment</Typography>
        <IntervalRuleForm onChange={setMildData} data={mildData} />
      </Grid>
      <Grid item xs={12}>
        <Typography sx={{ mb: 1 }}>Moderate corrosive environment</Typography>
        <IntervalRuleForm onChange={setModerateData} data={moderateData} />
      </Grid>
      <Grid item xs={12}>
        <Typography sx={{ mb: 1 }}>Severe corrosive environment</Typography>
        <IntervalRuleForm onChange={setSevereData} data={severeData} />
      </Grid>
    </Grid>
  );
}

export default EnvironmentRuleForm;
