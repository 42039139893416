import Stack from "@mui/material/Stack";
import differenceInDays from "date-fns/differenceInDays";
import format from "date-fns/format";
import * as React from "react";
import { locale } from "~config";
import { useFormatHours } from "~preferences";
import NextMaintenanceChip from "./NextMaintenanceChip";

interface Props {
  date?: Date | null;
  hours?: number | null;
  circles?: number | null;
}

function getDateColor(date: Date) {
  const daysFromNow = differenceInDays(date, new Date());
  if (daysFromNow <= 7) {
    return "error";
  }
  if (daysFromNow <= 30) {
    return "warning";
  }
  return "success";
}

function getHoursColor(hours: number) {
  if (hours <= 50) {
    return "error";
  }
  if (hours <= 100) {
    return "warning";
  }
  return "success";
}

function getCirclesColor(hours: number) {
  if (hours < 5) {
    return "error";
  }
  if (hours < 10) {
    return "warning";
  }
  return "success";
}

function NextMaintenanceReport({ date, hours, circles }: Props) {
  const formatHours = useFormatHours();
  return (
    <Stack direction="row" spacing={1}>
      <NextMaintenanceChip
        color={date ? getDateColor(date) : undefined}
        text={
          date &&
          format(new Date(date), "P", {
            locale,
          })
        }
      />
      <NextMaintenanceChip
        color={hours ? getHoursColor(hours) : undefined}
        text={hours ? formatHours(hours) : null}
        suffix="HR"
      />
      <NextMaintenanceChip
        color={circles ? getCirclesColor(circles) : undefined}
        text={circles}
        suffix="LDN"
      />
    </Stack>
  );
}

export default NextMaintenanceReport;
