import { useMutation, useQuery } from "@apollo/client";
import { Delete, Edit } from "@mui/icons-material";
import ListAltIcon from "@mui/icons-material/ListAlt";
import AddCircle from "@mui/icons-material/AddCircle";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid/Grid";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { Link } from "react-router-dom";
import { gql } from "~_generated/gql";
import { DeleteConfirmationModal } from "~components/modals";
import { PageContainer } from "~layout";
import paths from "~routes/paths";

export const LIST_MANUALS = gql(`
  query Programs {
    programs {
      items {
        id
        model
        name
        parent {
          id
          model
          name
        }
      }
      total
    }
  }
`);

export const DELETE_MANUAL = gql(`
  mutation DeleteProgram($id: ID!) {
    deleteProgram(id: $id)
  }`);

function ListProgramsPage() {
  const { data, error, loading } = useQuery(LIST_MANUALS);
  const [deleteVehicle, { loading: deleting, error: deleteError }] =
    useMutation(DELETE_MANUAL);
  const [itemToDelete, setItemToDelete] = React.useState<{
    id: string;
    model: string;
  }>();

  const handleDelete = (id: string) => {
    deleteVehicle({
      variables: { id },
      refetchQueries: [LIST_MANUALS],
    }).finally(() => setItemToDelete(undefined));
  };

  return (
    <PageContainer loading={loading} error={error?.message}>
      {deleteError && <Alert severity="error">{deleteError?.message}</Alert>}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Model</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Parent</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.programs?.items?.map((program) => (
                  <TableRow
                    key={program.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ minWidth: "8rem" }}
                    >
                      {program.model}
                    </TableCell>
                    <TableCell sx={{ minWidth: "8rem" }}>
                      {program.name}
                    </TableCell>
                    <TableCell>
                      {program.parent && program.parent.name}
                    </TableCell>
                    <TableCell
                      sx={{ whiteSpace: "nowrap", textAlign: "right" }}
                    >
                      <IconButton
                        component={Link}
                        to={paths.listProgramInstructions(program.id)}
                        aria-label="view instructions"
                      >
                        <ListAltIcon />
                      </IconButton>
                      <IconButton
                        component={Link}
                        to={paths.editProgram(program.id)}
                        aria-label="edit"
                      >
                        <Edit />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        onClick={() => setItemToDelete(program)}
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12}>
          <Button
            startIcon={<AddCircle />}
            component={Link}
            to={paths.createProgram}
            variant="contained"
            color="secondary"
          >
            Add Program
          </Button>
        </Grid>
      </Grid>
      {itemToDelete && (
        <DeleteConfirmationModal
          open={Boolean(itemToDelete)}
          deleting={deleting}
          itemName={`Program for ${itemToDelete.model} model`}
          onCancel={() => setItemToDelete(undefined)}
          onConfirm={() => handleDelete(itemToDelete.id)}
        />
      )}
    </PageContainer>
  );
}

export default ListProgramsPage;
