import { useMutation, useQuery } from "@apollo/client";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import * as React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { gql } from "~_generated/gql";
import { PageContainer, PageHeadline } from "~layout";
import paths from "~routes/paths";
import InstructionForm, { InstructionFormData } from "./InstructionForm";
import { LIST_MANUAL_INSTRUCTIONS } from "./ListProgramInstructionsPage";

export const GET_INSTRUCTION = gql(`
  query Instruction($id: ID!) {
    instruction(id: $id) {
      id
      programId
      index
      maintainableName
      maintainableIndex
      taskDescription
      notes
      manuals
      references
      tools
      materials
      intervalRule {
        counters {
          name
          value
        }
        thresholdCounters {
          name
          value
        }
      }
      parameterizedRule {
        parameter
        rules {
          value
          counters {
            name
            value
          }
          thresholdCounters {
            name
            value
          }
        }
      }
    }
  }
`);

export const UPDATE_INSTRUCTION = gql(`
  mutation UpdateInstruction($id: ID!, $data: UpdateInstructionInput!) {
    updateInstruction(id: $id, data: $data) {
        id
      }  
    }
`);

function EditInstructionPage() {
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id || "";
  const { data, loading, error } = useQuery(GET_INSTRUCTION, {
    variables: { id },
    fetchPolicy: "network-only",
  });
  const instruction = data?.instruction || undefined;
  const [update, { loading: updateLoading, error: updateError }] =
    useMutation(UPDATE_INSTRUCTION);

  const handleSubmit = (data: InstructionFormData) => {
    const variables = {
      id,
      data,
    };
    update({
      variables,
      refetchQueries: [LIST_MANUAL_INSTRUCTIONS],
    }).then(() =>
      navigate(paths.listProgramInstructions(instruction?.programId || ""))
    );
  };

  return (
    <PageContainer loading={loading} error={error?.message}>
      <PageHeadline>Updating instruction of program</PageHeadline>
      {updateError && <Alert severity="error">{updateError?.message}</Alert>}
      {instruction && (
        <InstructionForm data={instruction} onSubmit={handleSubmit}>
          <Button
            component={Link}
            to={paths.listProgramInstructions(instruction.programId || "")}
            variant="contained"
            color="secondary"
            sx={{ mr: 1 }}
          >
            Back
          </Button>
          <Button type="submit" variant="contained" disabled={updateLoading}>
            Save changes
          </Button>
        </InstructionForm>
      )}
    </PageContainer>
  );
}

export default EditInstructionPage;
