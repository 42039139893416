import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import * as React from "react";
import { useState } from "react";
import { Program } from "~_generated/graphql";
import ParentProgramSelector from "~routes/Programs/ParentProgramSelector";

export type ProgramFormData = Pick<Program, "model" | "name" | "parentId">;
interface ComponentProps {
  id?: string;
  data?: ProgramFormData;
  onSubmit: (data: ProgramFormData) => void;
  children: React.ReactNode;
}

function ProgramForm({ id, data, onSubmit, children }: ComponentProps) {
  const [model, setModel] = useState<string>(data?.model || "");
  const [name, setName] = useState<string>(data?.name || "");
  const [parentId, setParentId] = useState<string | null>(
    data?.parentId || null
  );

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit({
      model,
      name,
      parentId,
    });
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            label="Model"
            value={model}
            onChange={(event) => setModel(event.currentTarget.value)}
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            label="Name"
            value={name}
            onChange={(event) => setName(event.currentTarget.value)}
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ParentProgramSelector
            programId={id}
            selectedId={parentId}
            onSelectId={setParentId}
            label="Parent Program"
            variant="standard"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    </Box>
  );
}

export default ProgramForm;
