import FormControl from "@mui/material/FormControl";
import Input, { InputProps } from "@mui/material/Input";
import InputLabel, { InputLabelProps } from "@mui/material/InputLabel";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import * as React from "react";
import { useCallback, useContext, useState } from "react";
import { IMask, IMaskInput } from "react-imask";
import { HoursFormat, PreferencesContext } from "~preferences";

interface CustomProps {
  onChange?: (event: any | { currentTarget: { value: string } }) => void;
}

const HoursMask = React.forwardRef<HTMLInputElement, CustomProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="HH:`M1M2"
        blocks={{
          HH: {
            mask: Number,
            min: 0,
            max: 9999999,
            scale: 0,
            normalizeZeros: false,
            padFractionalZeros: false,
          },
          M1: {
            mask: IMask.MaskedRange,
            from: 0,
            to: 5,
          },
          M2: {
            mask: IMask.MaskedRange,
            from: 0,
            to: 9,
          },
        }}
        lazy={false}
        placeholderChar="0"
        inputRef={ref}
        onChange={() => {}}
        onAccept={(value) =>
          onChange &&
          onChange({
            currentTarget: { value: String(value) },
          })
        }
      />
    );
  }
);

function roundToTwo(number: number) {
  return +(Math.round(Number(number + "e+2")) + "e-2");
}

interface Props extends Omit<InputProps, "onChange" | "value"> {
  variant?: "standard" | "filled" | "outlined";
  label?: string;
  inputLabelProps?: InputLabelProps;
  value: number | null;
  onChange?: (value: number | null) => void;
}

function HoursField(props: Props) {
  const { hoursFormat, formatHours } = useContext(PreferencesContext);
  const { variant, label, onChange, inputLabelProps, ...inputProps } = props;
  const formattedValue = props.value === null ? "" : formatHours(props.value);
  const [isEmpty, setIsEmpty] = useState<boolean>(props.value === null);

  const toggleEmpty = useCallback(() => {
    const newIsEmpty = !isEmpty;
    setIsEmpty(newIsEmpty);
    if (newIsEmpty) {
      onChange && onChange(null);
    } else {
      onChange && onChange(0);
    }
  }, [isEmpty, setIsEmpty, onChange]);

  const onChangeWithDecoding = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const stringValue = event.currentTarget.value;
      if (stringValue === "") {
        onChange && onChange(null);
      }
      switch (hoursFormat) {
        case HoursFormat.H:
          onChange && onChange(Number(stringValue));
          break;

        case HoursFormat.HM:
          const [hours, minutes] = stringValue.split(":");
          const decimals = Number(minutes || 0) / 60;
          const decimalValue = roundToTwo(Number(hours) + decimals);
          onChange && onChange(decimalValue);
          break;
      }
    },
    [hoursFormat, onChange]
  );

  switch (hoursFormat) {
    case HoursFormat.H:
      return (
        <FormControl variant={variant} fullWidth={inputProps.fullWidth}>
          <InputLabel {...inputLabelProps}>{label}</InputLabel>
          <Input
            type="number"
            onChange={onChangeWithDecoding}
            {...inputProps}
          />
        </FormControl>
      );
    case HoursFormat.HM:
      return (
        <Stack direction="row" spacing={1} alignItems="flex-end">
          <FormControl variant={variant} fullWidth={inputProps.fullWidth}>
            <InputLabel {...inputLabelProps}>{label}</InputLabel>
            {isEmpty ? (
              <Input key="empty" {...inputProps} value="" disabled />
            ) : (
              <Input
                key="mask"
                {...inputProps}
                inputComponent={HoursMask}
                value={formattedValue}
                onChange={onChangeWithDecoding}
              />
            )}
          </FormControl>
          {!props.required && (
            <Switch checked={!isEmpty} onChange={toggleEmpty} />
          )}
        </Stack>
      );
  }
}
export default React.memo(HoursField);
