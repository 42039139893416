import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import * as React from "react";
import { useState } from "react";
import { WorkOrder } from "~_generated/graphql";

export type WorkOrderFormData = Pick<WorkOrder, "name">;
interface Props {
  data?: WorkOrderFormData;
  onSubmit: (data: WorkOrderFormData) => void;
  children: React.ReactNode;
}

function WorkOrderForm({ data, onSubmit, children }: Props) {
  const [name, setName] = useState<string>(data?.name || "");

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit({
      name,
    });
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            label="Name"
            value={name}
            onChange={(event) => setName(event.currentTarget.value)}
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    </Box>
  );
}

export default WorkOrderForm;
