import { gql } from "~_generated/gql";

export const GET_VEHICLE = gql(`
  query Vehicle($vehicleId: ID!) {
    vehicle(id: $vehicleId) {
      id
      model
      serial
      registrationNumber
      status
      maintainableId
      maintainable {
        id
        date
        counters {
          name
          value
        }
        programIds
        parameters {
          environment
        }
      }
    }
  }
`);

export const VEHICLE_NEXT_MAINTENANCE = gql(`
  query VehicleNextMaintenance($id: ID!) {
    vehicle(id: $id) {
      id
      nextMaintenance(recursive: true) {
        date
        hours
        circles
      }
    }
  }
`);
