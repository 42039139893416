import Typography from "@mui/material/Typography";
import * as React from "react";

interface PageHeadlineProps {
  children: React.ReactNode;
}
function PageHeadlineComponent({ children }: PageHeadlineProps) {
  return (
    <Typography variant="h6" gutterBottom>
      {children}
    </Typography>
  );
}
export default React.memo(PageHeadlineComponent);
