import { useMutation, useQuery } from "@apollo/client";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import * as React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { gql } from "~_generated/gql";
import { PageContainer, PageHeadline } from "~layout";
import paths from "~routes/paths";
import { LIST_WORK_ORDERS } from "./ListWorkOrdersPage";
import WorkOrderForm, { WorkOrderFormData } from "./WorkOrderForm";

export const GET_WORK_ORDER = gql(`
  query WorkOrder($id: ID!) {
    workOrder(id: $id) {
      id
      name
    }
  }
`);

export const UPDATE_WORK_ORDER = gql(`
  mutation UpdateWorkOrder($id: ID!, $data: UpdateWorkOrderInput!) {
    updateWorkOrder(id: $id, data: $data) {
        id
        name
      }  
    }
`);

function EditWorkOrderPage() {
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id || "";
  const { data, loading, error } = useQuery(GET_WORK_ORDER, {
    variables: { id },
  });
  const workOrder = data?.workOrder || undefined;
  const [update, { loading: updateLoading, error: updateError }] =
    useMutation(UPDATE_WORK_ORDER);

  const handleSubmit = (data: WorkOrderFormData) => {
    const variables = {
      id,
      data,
    };
    update({ variables, refetchQueries: [LIST_WORK_ORDERS] }).then(() =>
      navigate(paths.listWorkOrders)
    );
  };

  return (
    <PageContainer loading={loading} error={error?.message}>
      <PageHeadline>Edit work order</PageHeadline>
      {updateError && <Alert severity="error">{updateError?.message}</Alert>}
      {workOrder && (
        <WorkOrderForm data={workOrder} onSubmit={handleSubmit}>
          <Button
            component={Link}
            to={paths.listWorkOrders}
            variant="contained"
            color="secondary"
            sx={{ mr: 1 }}
          >
            Back
          </Button>
          <Button type="submit" variant="contained" disabled={updateLoading}>
            Save changes
          </Button>
        </WorkOrderForm>
      )}
    </PageContainer>
  );
}

export default EditWorkOrderPage;
