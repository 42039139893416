export function transformCounters(
  names: Array<string>,
  values: Array<string | number | null>
) {
  let counters: Array<{ name: string; value: number }> = [];
  names.forEach((name, index) => {
    if (values[index] !== null && values[index] !== "") {
      counters.push({ name, value: Number(values[index]) });
    }
  });
  return counters.length ? counters : null;
}
