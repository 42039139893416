import Typography from "@mui/material/Typography";
import * as React from "react";

interface Props {
  name: string;
  baseCounters?: Array<{ name: string; value: number }>;
  modifiedCounters?: Array<{ name: string; value: number }>;
}

function ModificationRuleCounterValue({
  name,
  modifiedCounters,
  baseCounters,
}: Props) {
  const modifiedCounter =
    modifiedCounters && modifiedCounters.find((item) => item.name === name);
  const baseCounter =
    baseCounters && baseCounters.find((item) => item.name === name);

  if (modifiedCounter) {
    return <>{modifiedCounter.value}</>;
  }
  if (baseCounter) {
    return (
      <Typography color="text.disabled" variant="inherit">
        {baseCounter.value}
      </Typography>
    );
  }
  return null;
}
export default React.memo(ModificationRuleCounterValue);
