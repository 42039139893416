import { useMutation, useQuery } from "@apollo/client";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { gql } from "~_generated/gql";
import { GET_COMPONENT, LIST_COMPONENTS } from "~api/components";
import ProgramsSelector from "~components/ProgramsSelector";
import { PageContainer, PageHeadline } from "~layout";
import paths from "~routes/paths";
import ComponentForm, { ComponentFormData } from "./ComponentForm";

export const UPDATE_COMPONENT = gql(`
  mutation UpdateComponent($id: ID!, $data: UpdateComponentInput!) {
    updateComponent(id: $id, data: $data) {
      id
    }
  }`);

function EditComponentPage() {
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id || "";
  const { data, loading, error } = useQuery(GET_COMPONENT, {
    variables: { id },
  });

  const component = data?.component || undefined;
  const [update, { loading: updateLoading, error: updateError }] =
    useMutation(UPDATE_COMPONENT);

  const [programIds, setProgramIds] = useState<Array<string>>([]);

  useEffect(() => {
    if (component) {
      setProgramIds(component.maintainable?.programIds || []);
    }
  }, [component]);

  const handleSubmit = (data: ComponentFormData) => {
    const variables = {
      id,
      data: {
        ...data,
        maintainable: {
          ...data.maintainable,
          programIds,
        },
      },
    };
    update({
      variables,
      refetchQueries: [LIST_COMPONENTS, GET_COMPONENT],
    }).then(() => {
      if (data.parentId) {
        navigate(paths.componentsOfComponent(data.parentId));
      } else if (component?.vehicleId) {
        navigate(paths.listVehicleComponents(component.vehicleId));
      }
    });
  };

  return (
    <PageContainer loading={loading} error={error?.message}>
      <PageHeadline>Updating component</PageHeadline>
      {updateError && <Alert severity="error">{updateError?.message}</Alert>}
      {component && (
        <ComponentForm
          data={component}
          onSubmit={handleSubmit}
          id={component.id}
          vehicleId={component.vehicleId}
        >
          <Stack spacing={3}>
            <Stack direction="column" spacing={1}>
              <Typography>Linked programs</Typography>
              <ProgramsSelector
                selection={programIds}
                setSelection={setProgramIds}
              />
            </Stack>
            <Stack direction="row" spacing={1}>
              <Button
                component={Link}
                to={paths.listVehicleComponents(component.vehicleId || "")}
                variant="contained"
                color="secondary"
              >
                Back
              </Button>
              <Button
                type="submit"
                variant="contained"
                disabled={updateLoading}
              >
                Save changes
              </Button>
            </Stack>
          </Stack>
        </ComponentForm>
      )}
    </PageContainer>
  );
}

export default EditComponentPage;
