import { useMutation, useQuery } from "@apollo/client";
import CheckIcon from "@mui/icons-material/Check";
import AddCircle from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid/Grid";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { Link } from "react-router-dom";
import { gql } from "~_generated/gql";
import { DeleteConfirmationModal } from "~components/modals";
import { PageContainer } from "~layout";
import paths from "~routes/paths";

export const LIST_USERS = gql(`
  query Users {
    users {
      items {
        id
        username
        firstName
        lastName
        isAdmin
      }
      total
    }
  }
`);

export const DELETE_USER = gql(`
  mutation DeleteUser($id: ID!) {
    deleteUser(id: $id)
  }`);

function ListUsersPage() {
  const { data, error, loading } = useQuery(LIST_USERS);
  const [deleteVehicle, { loading: deleting, error: deleteError }] =
    useMutation(DELETE_USER);
  const [itemToDelete, setItemToDelete] = React.useState<{
    id: string;
    username?: string | null;
  }>();

  const handleDelete = (id: string) => {
    deleteVehicle({
      variables: { id },
      refetchQueries: [LIST_USERS],
    }).finally(() => setItemToDelete(undefined));
  };

  return (
    <PageContainer loading={loading} error={error?.message}>
      {deleteError && <Alert severity="error">{deleteError?.message}</Alert>}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Username</TableCell>
                  <TableCell>First name</TableCell>
                  <TableCell>Last name</TableCell>
                  <TableCell>Admin</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.users?.items?.map((user) => (
                  <TableRow
                    key={user.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ minWidth: "8rem" }}
                    >
                      {user.username}
                    </TableCell>
                    <TableCell sx={{ minWidth: "8rem" }}>
                      {user.firstName}
                    </TableCell>
                    <TableCell sx={{ minWidth: "8rem" }}>
                      {user.lastName}
                    </TableCell>
                    <TableCell>
                      {user.isAdmin && <CheckIcon color="success" />}
                    </TableCell>
                    <TableCell
                      sx={{ whiteSpace: "nowrap", textAlign: "right" }}
                    >
                      <IconButton
                        component={Link}
                        to={paths.admin.editUser(user.id)}
                        aria-label="edit"
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        onClick={() => setItemToDelete(user)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12}>
          <Button
            startIcon={<AddCircle />}
            component={Link}
            to={paths.admin.createUser}
            variant="contained"
            color="secondary"
          >
            Add User
          </Button>
        </Grid>
      </Grid>
      {itemToDelete && (
        <DeleteConfirmationModal
          open={true}
          deleting={deleting}
          itemName={`${itemToDelete.username}`}
          onCancel={() => setItemToDelete(undefined)}
          onConfirm={() => handleDelete(itemToDelete.id)}
        />
      )}
    </PageContainer>
  );
}

export default ListUsersPage;
