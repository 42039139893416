import { useMutation, useQuery } from "@apollo/client";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import * as React from "react";
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { gql } from "~_generated/gql";
import { PageContainer, PageHeadline } from "~layout";
import { LIST_MANUALS } from "~routes/Programs/ListProgramsPage";
import paths from "~routes/paths";
import ProgramForm, { ProgramFormData } from "./ProgramForm";

export const GET_MANUAL = gql(`
  query Program($id: ID!) {
    program(id: $id) {
      id
      model
      name
      parentId
    }
  }
`);
export const UPDATE_MANUAL = gql(`
  mutation UpdateProgram($id: ID!, $data: UpdateProgramInput!) {
    updateProgram(id: $id, data: $data) {
      model
      name
    }
  }`);

function CreateVehiclePage() {
  const params = useParams();
  const id = params.id || "";
  const { data, error, loading } = useQuery(GET_MANUAL, {
    variables: { id },
  });
  const [
    update,
    { data: updateData, loading: updateLoading, error: updateError },
  ] = useMutation(UPDATE_MANUAL);
  const navigate = useNavigate();

  useEffect(() => {
    if (updateData) {
      navigate(paths.listPrograms);
    }
  }, [navigate, updateData]);

  const handleSubmit = (formData: ProgramFormData) => {
    const variables = {
      id,
      data: formData,
    };
    update({ variables, refetchQueries: [LIST_MANUALS, GET_MANUAL] });
  };

  return (
    <PageContainer loading={loading} error={error?.message}>
      <PageHeadline>
        {data?.program?.model}: {data?.program?.name}
      </PageHeadline>
      {updateError && <Alert severity="error">{updateError?.message}</Alert>}
      {data?.program && (
        <ProgramForm id={id} data={data.program} onSubmit={handleSubmit}>
          <Button
            component={Link}
            to={paths.listPrograms}
            variant="contained"
            color="secondary"
            sx={{ mr: 1 }}
          >
            Back
          </Button>
          <Button type="submit" variant="contained" disabled={updateLoading}>
            Save changes
          </Button>
        </ProgramForm>
      )}
    </PageContainer>
  );
}

export default CreateVehiclePage;
