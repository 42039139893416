import { useMutation } from "@apollo/client";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import { gql } from "~_generated/gql";
import { PageContainer, PageHeadline } from "~layout";
import paths from "~routes/paths";
import { LIST_USERS } from "./ListUsersPage";
import UserForm, { UserFormData } from "./UserForm";

export const CREATE_USER = gql(`
  mutation CreateUser($data: CreateUserInput!) {
    createUser(data: $data) {
      id
    }
  }`);

function CreateUserPage() {
  const [create, { loading, error }] = useMutation(CREATE_USER);
  const navigate = useNavigate();

  const handleSubmit = (data: UserFormData) => {
    const password = data.password || "";
    create({
      variables: { data: { ...data, password } },
      refetchQueries: [LIST_USERS],
    }).then(() => navigate(paths.admin.listUsers));
  };

  return (
    <PageContainer>
      <PageHeadline>Creating new User</PageHeadline>
      {error && <Alert severity="error">{error?.message}</Alert>}
      <UserForm onSubmit={handleSubmit}>
        <Button
          component={Link}
          to={paths.admin.listUsers}
          variant="contained"
          color="secondary"
          sx={{ mr: 1 }}
        >
          Back
        </Button>
        <Button type="submit" variant="contained" disabled={loading}>
          Create
        </Button>
      </UserForm>
    </PageContainer>
  );
}

export default CreateUserPage;
