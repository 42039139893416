import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import * as React from "react";
import { useState } from "react";

interface ComponentProps {
  open: boolean;
  onConfirm: (date: Date | null, name: string | null) => void;
  onCancel: () => void;
  disabled?: boolean;
  loading?: boolean;
  error?: string;
  title: string;
  date?: Date;
  name?: string;
}

function EditStatusModal(props: ComponentProps) {
  const [date, setDate] = useState<Date | null>(props.date || null);
  const [name, setName] = useState<string>(props.name || "");

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    props.onConfirm(date, name === "" ? null : name);
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <form id="edit-status-form" onSubmit={handleSubmit}>
          {props.error && <Alert severity="error">{props.error}</Alert>}
          <DatePicker
            disabled={props.disabled}
            label="Date"
            value={date}
            onChange={(value) => setDate(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                sx={{ pb: 2 }}
                fullWidth
              />
            )}
          />
          <TextField
            disabled={props.disabled}
            label="Responsible person"
            placeholder="Ex: Ivan Ivanov"
            value={name}
            onChange={(event) => setName(event.currentTarget.value)}
            fullWidth
            variant="standard"
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onCancel} color="info">
          Cancel
        </Button>
        <Button
          type="submit"
          form="edit-status-form"
          disabled={props.loading || props.disabled}
          color="success"
          autoFocus
        >
          Save changes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default EditStatusModal;
