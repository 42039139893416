import Typography from "@mui/material/Typography";
import differenceInDays from "date-fns/differenceInDays";
import format from "date-fns/format";
import * as React from "react";
import { locale } from "~config";

export function getColor(date: Date, nextDate: Date) {
  const diff = differenceInDays(date, nextDate);
  if (diff <= 0) {
    return "error.main";
  }
  if (diff <= 10) {
    return "warning.main";
  }
  return "success.main";
}

interface Props {
  date: Date;
  maintenanceDate: Date;
}

function NextDateValue({ date, maintenanceDate }: Props) {
  return (
    <Typography
      variant="inherit"
      color={getColor(new Date(date), maintenanceDate)}
    >
      {date &&
        format(new Date(date), "P", {
          locale,
        })}
    </Typography>
  );
}
export default NextDateValue;
