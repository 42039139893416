import { useQuery } from "@apollo/client";
import { Add, Delete } from "@mui/icons-material";
import Alert from "@mui/material/Alert";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { Program } from "~_generated/graphql";
import { LIST_MANUALS } from "~routes/Programs/ListProgramsPage";

interface Props {
  selection: Array<string>;
  setSelection: Dispatch<SetStateAction<string[]>>;
}

function ProgramsSelector({ selection, setSelection }: Props) {
  const [selectedId, setSelectedId] = useState<string>();

  const { data, error } = useQuery(LIST_MANUALS);
  const programs = useMemo(() => data?.programs?.items || [], [data]);

  const selectedPrograms: Array<Program> = useMemo(() => {
    return selection
      .map((id) => programs.find((program) => program.id === id))
      .filter((program): program is Program => Boolean(program));
  }, [programs, selection]);

  const unselectedPrograms: Array<Program> = useMemo(() => {
    return programs.filter((program) => !selection.includes(program.id));
  }, [programs, selection]);

  useEffect(() => {
    unselectedPrograms.length && setSelectedId(unselectedPrograms[0].id);
  }, [unselectedPrograms]);

  return (
    <Stack spacing={2}>
      {error && <Alert severity="error">{error?.message}</Alert>}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Program</TableCell>
              <TableCell>Name</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedPrograms.map((program) => (
              <TableRow key={program.id}>
                <TableCell component="th" scope="row">
                  {program.model}
                </TableCell>
                <TableCell>{program.name}</TableCell>
                <TableCell align="right">
                  <IconButton
                    onClick={() =>
                      setSelection((selection) =>
                        selection.filter((id) => id !== program.id)
                      )
                    }
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableBody>
            {unselectedPrograms.length !== 0 && selectedId && (
              <TableRow>
                <TableCell colSpan={2}>
                  <FormControl fullWidth>
                    <Select
                      value={selectedId}
                      onChange={(event) => setSelectedId(event.target.value)}
                      label="Program"
                      variant="standard"
                    >
                      {unselectedPrograms.map((program) => (
                        <MenuItem value={program.id} key={program.id}>
                          {program.model}: {program.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell align="right">
                  <IconButton
                    onClick={() =>
                      setSelection((selection) =>
                        selectedId ? [...selection, selectedId] : selection
                      )
                    }
                  >
                    <Add />
                  </IconButton>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
}

export default ProgramsSelector;
