import { useMutation } from "@apollo/client";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import * as React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { gql } from "~_generated/gql";
import { LIST_COMPONENTS } from "~api/components";
import { PageContainer, PageHeadline } from "~layout";
import paths from "~routes/paths";
import ComponentForm, { ComponentFormData } from "./ComponentForm";

export const CREATE_COMPONENT = gql(`
  mutation CreateComponent($data: CreateComponentInput!) {
    createComponent(data: $data) {
      id
    }
  }`);

function CreateVehicleComponentPage() {
  const navigate = useNavigate();
  const params = useParams();
  const vehicleId = params.id || "";
  const relativeToId = params.maintainableId || "";
  const [create, { loading, error }] = useMutation(CREATE_COMPONENT);

  const handleSubmit = (data: ComponentFormData) => {
    const variables = {
      data: {
        ...data,
        maintainable: { ...data.maintainable, relativeToId },
        vehicleId,
      },
    };
    create({
      variables,
      refetchQueries: [LIST_COMPONENTS],
    }).then(() => navigate(paths.listVehicleComponents(vehicleId)));
  };

  return (
    <PageContainer>
      <PageHeadline>Creating component</PageHeadline>
      {error && <Alert severity="error">{error?.message}</Alert>}
      <ComponentForm onSubmit={handleSubmit} vehicleId={vehicleId}>
        <Button
          component={Link}
          to={paths.listVehicleComponents(vehicleId)}
          variant="contained"
          color="secondary"
          sx={{ mr: 1 }}
        >
          Back
        </Button>
        <Button type="submit" variant="contained" disabled={loading}>
          Save changes
        </Button>
      </ComponentForm>
    </PageContainer>
  );
}

export default CreateVehicleComponentPage;
