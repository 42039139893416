import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import * as React from "react";
import { useEffect, useState } from "react";
import { IntervalRule } from "~_generated/graphql";
import { transformCounters } from "~components/components.utils";

export type IntervalRuleData = Pick<
  IntervalRule,
  "counters" | "thresholdCounters"
>;
interface ComponentProps {
  data?: IntervalRuleData;
  onChange: (data: IntervalRuleData) => void;
}

function IntervalRuleForm({ data, onChange }: ComponentProps) {
  const dataHours = data?.counters?.find((item) => item.name === "hours");
  const [intervalHours, setIntervalHours] = useState<string>(
    dataHours ? String(dataHours?.value) : ""
  );
  const dataCircles = data?.counters?.find((item) => item.name === "circles");
  const [intervalCircles, setIntervalCircles] = useState<string>(
    dataCircles ? String(dataCircles?.value) : ""
  );
  const dataDays = data?.counters?.find((item) => item.name === "days");
  const [intervalDays, setIntervalDays] = useState<string>(
    dataDays ? String(dataDays?.value) : ""
  );
  const thDataHours = data?.thresholdCounters?.find(
    (item) => item.name === "hours"
  );
  const [thresholdHours, setThresholdHours] = useState<string>(
    thDataHours ? String(thDataHours?.value) : ""
  );
  const thDataCircles = data?.thresholdCounters?.find(
    (item) => item.name === "circles"
  );
  const [thresholdCircles, setThresholdCircles] = useState<string>(
    thDataCircles ? String(thDataCircles?.value) : ""
  );
  const thDataDays = data?.thresholdCounters?.find(
    (item) => item.name === "days"
  );
  const [thresholdDays, setThresholdDays] = useState<string>(
    thDataDays ? String(thDataDays?.value) : ""
  );

  useEffect(() => {
    const data = {
      counters: transformCounters(
        ["hours", "circles", "days"],
        [intervalHours, intervalCircles, intervalDays]
      ),
      thresholdCounters: transformCounters(
        ["hours", "circles", "days"],
        [thresholdHours, thresholdCircles, thresholdDays]
      ),
    };
    onChange(
      Object.fromEntries(Object.entries(data).filter(([_, v]) => v != null))
    );
  }, [
    intervalHours,
    intervalCircles,
    intervalDays,
    thresholdHours,
    thresholdCircles,
    thresholdDays,
    onChange,
  ]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={4}>
        <TextField
          type="number"
          label="Interval in flight hours"
          value={intervalHours}
          onChange={(event) => setIntervalHours(event.currentTarget.value)}
          fullWidth
          variant="standard"
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          type="number"
          label="Interval in flight circles"
          value={intervalCircles}
          onChange={(event) => setIntervalCircles(event.currentTarget.value)}
          fullWidth
          variant="standard"
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          type="number"
          label="Interval in days"
          value={intervalDays}
          onChange={(event) => setIntervalDays(event.currentTarget.value)}
          fullWidth
          variant="standard"
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          type="number"
          label="Threshold in flight hours"
          value={thresholdHours}
          onChange={(event) => setThresholdHours(event.currentTarget.value)}
          fullWidth
          variant="standard"
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          type="number"
          label="Threshold in flight circles"
          value={thresholdCircles}
          onChange={(event) => setThresholdCircles(event.currentTarget.value)}
          fullWidth
          variant="standard"
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          type="number"
          label="Threshold in days"
          value={thresholdDays}
          onChange={(event) => setThresholdDays(event.currentTarget.value)}
          fullWidth
          variant="standard"
        />
      </Grid>
    </Grid>
  );
}

export default React.memo(IntervalRuleForm);
