import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import * as React from "react";
import { useState } from "react";
import { User } from "~_generated/graphql";

export type UserFormData = Pick<
  User,
  "username" | "firstName" | "lastName" | "isAdmin"
> & {
  password?: string;
};
interface ComponentProps {
  data?: Omit<UserFormData, "password">;
  onSubmit: (data: UserFormData) => void;
  children: React.ReactNode;
}

function UserForm({ data, onSubmit, children }: ComponentProps) {
  const [username, setUsername] = useState<string>(data?.username || "");
  const [password, setPassword] = useState<string>("");
  const [firstName, setFirstName] = useState<string>(data?.firstName || "");
  const [lastName, setLastName] = useState<string>(data?.lastName || "");
  const [isAdmin, setIsAdmin] = useState<boolean>(data?.isAdmin || false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit({
      username,
      password: password || undefined,
      firstName,
      lastName,
      isAdmin,
    });
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            label="Username"
            value={username}
            onChange={(event) => setUsername(event.currentTarget.value)}
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Password"
            value={password}
            onChange={(event) => setPassword(event.currentTarget.value)}
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="First name"
            value={firstName}
            onChange={(event) => setFirstName(event.currentTarget.value)}
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Last name"
            value={lastName}
            onChange={(event) => setLastName(event.currentTarget.value)}
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isAdmin}
                onChange={() => setIsAdmin((item) => !item)}
              />
            }
            label="Admin"
          />
        </Grid>
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    </Box>
  );
}

export default UserForm;
