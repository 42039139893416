import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import * as React from "react";
import { useState } from "react";
import { Instruction, InstructionModification } from "~_generated/graphql";
import RulesEditor from "../RulesEditor";

export type InstructionModFormData = Pick<
  InstructionModification,
  | "isIgnored"
  | "index"
  | "maintainableName"
  | "maintainableIndex"
  | "taskDescription"
  | "notes"
  | "manuals"
  | "references"
  | "tools"
  | "materials"
  | "intervalRule"
  | "parameterizedRule"
>;
type InstructionData = Pick<
  Instruction,
  | "index"
  | "maintainableName"
  | "maintainableIndex"
  | "taskDescription"
  | "notes"
  | "manuals"
  | "references"
  | "tools"
  | "materials"
  | "intervalRule"
  | "parameterizedRule"
>;

interface ComponentProps {
  data?: InstructionModFormData;
  instruction: InstructionData;
  onSubmit: (data: InstructionModFormData) => void;
  children: React.ReactNode;
}

function InstructionForm({
  data,
  instruction,
  onSubmit,
  children,
}: ComponentProps) {
  const [isIgnored, setIsIgnored] = useState<boolean>(data?.isIgnored || false);
  const [index, setIndex] = useState<string>(data?.index || "");
  const [maintainableName, setMaintainableName] = useState<string>(
    data?.maintainableName || ""
  );
  const [maintainableIndex, setMaintainableIndex] = useState<string>(
    data?.maintainableIndex || ""
  );
  const [taskDescription, setTaskDescription] = useState<string>(
    data?.taskDescription || ""
  );
  const [notes, setNotes] = useState<string>(data?.notes || "");
  const [manuals, setManuals] = useState<string>(data?.manuals || "");
  const [references, setReferences] = useState<string>(data?.references || "");
  const [tools, setTools] = useState<string>(data?.tools || "");
  const [materials, setMaterials] = useState<string>(data?.materials || "");
  const [rules, setRules] = useState<
    Pick<Instruction, "intervalRule" | "parameterizedRule"> | undefined
  >({
    intervalRule: data?.intervalRule,
    parameterizedRule: data?.parameterizedRule,
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit({
      isIgnored,
      index: index || null,
      maintainableName: maintainableName || null,
      maintainableIndex: maintainableIndex || null,
      taskDescription: taskDescription || null,
      notes: notes || null,
      manuals: manuals || null,
      references: references || null,
      tools: tools || null,
      materials: materials || null,
      ...rules,
    });
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isIgnored}
                onChange={() => setIsIgnored((state) => !state)}
              />
            }
            label="Ignore instruction"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Task No"
            placeholder={instruction.index}
            value={index}
            onChange={(event) => setIndex(event.currentTarget.value)}
            fullWidth
            variant="standard"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Item index"
            placeholder={String(instruction.maintainableIndex)}
            value={maintainableIndex}
            onChange={(event) =>
              setMaintainableIndex(event.currentTarget.value)
            }
            fullWidth
            variant="standard"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            label="Item name"
            placeholder={instruction.maintainableName}
            value={maintainableName}
            onChange={(event) => setMaintainableName(event.currentTarget.value)}
            fullWidth
            variant="standard"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            multiline
            label="Task Description"
            placeholder={instruction.taskDescription}
            value={taskDescription}
            onChange={(event) => setTaskDescription(event.currentTarget.value)}
            fullWidth
            variant="standard"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            multiline
            label="Notes"
            placeholder={instruction.notes || ""}
            value={notes}
            onChange={(event) => setNotes(event.currentTarget.value)}
            fullWidth
            variant="standard"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            multiline
            label="Manuals"
            placeholder={instruction.manuals || ""}
            value={manuals}
            onChange={(event) => setManuals(event.currentTarget.value)}
            fullWidth
            variant="standard"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            multiline
            label="Reference documents"
            placeholder={instruction.references || ""}
            value={references}
            onChange={(event) => setReferences(event.currentTarget.value)}
            fullWidth
            variant="standard"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            multiline
            label="Tools"
            placeholder={instruction.tools || ""}
            value={tools}
            onChange={(event) => setTools(event.currentTarget.value)}
            fullWidth
            variant="standard"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            multiline
            label="Materials"
            placeholder={instruction.materials || ""}
            value={materials}
            onChange={(event) => setMaterials(event.currentTarget.value)}
            fullWidth
            variant="standard"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <RulesEditor onChange={setRules} data={rules} />
        </Grid>
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    </Box>
  );
}

export default InstructionForm;
