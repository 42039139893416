import { Chip } from "@mui/material";
import * as React from "react";

interface Props {
  text?: string | number | null;
  suffix?: string;
  color?: "success" | "error" | "warning" | null;
}

function NextMaintenanceChip({ text, suffix, color }: Props) {
  const label = text ? `${text}${suffix ? " " + suffix : ""}` : "no data";
  return (
    <Chip
      label={label}
      color={text && color ? color : undefined}
      sx={!text ? { color: "text.disabled" } : {}}
      variant="outlined"
    />
  );
}

export default React.memo(NextMaintenanceChip);
