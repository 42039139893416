import { FormControl } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import * as React from "react";
import { useState } from "react";
import { HoursFormat } from "~preferences";

export interface PreferencesFormData {
  hoursFormat: HoursFormat;
}
interface ComponentProps {
  data: PreferencesFormData;
  onSubmit: (data: PreferencesFormData) => void;
  children: React.ReactNode;
}

const formats = {
  [HoursFormat.HM]: "Hours:Minutes, example: 22:45",
  [HoursFormat.H]: "Decimal hours, example: 22.7",
};

function PreferencesForm({ data, onSubmit, children }: ComponentProps) {
  const [hoursFormat, setHoursFormat] = useState<HoursFormat>(
    data?.hoursFormat || HoursFormat.H
  );
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit({
      hoursFormat,
    });
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel id="status">Hours format</InputLabel>
            <Select
              value={hoursFormat}
              onChange={(event) =>
                setHoursFormat(
                  event.target.value
                    ? (event.target.value as HoursFormat)
                    : HoursFormat.HM
                )
              }
            >
              {Object.entries(formats).map(([value, title]) => (
                <MenuItem value={value} key={value}>
                  {title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    </Box>
  );
}

export default PreferencesForm;
