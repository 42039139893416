import { createContext } from "react";
import { HoursFormat } from "./Preferences.types";

interface PreferencesContextProps {
  hoursFormat: HoursFormat;
  formatHours: (hours: number) => string;
  setHoursFormat: (format: HoursFormat) => void;
}

const defaultContext: PreferencesContextProps = {
  hoursFormat: HoursFormat.H,
  formatHours: () => "No provider",
  setHoursFormat: () => {},
};

export const PreferencesContext =
  createContext<PreferencesContextProps>(defaultContext);
