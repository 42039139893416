const paths = {
  dashboard: "/",
  listVehicles: "/vehicles",
  createVehicle: "/vehicles/create",
  editVehicle: (id: string) => `/vehicles/${id}/edit`,
  listVehicleComponents: (id: string) => `/vehicles/${id}/components`,
  createVehicleComponent: (id: string, maintainableId: string) =>
    `/vehicles/${id}/${maintainableId}/components/create`,
  uploadVehicleComponents: (id: string, maintainableId: string) =>
    `/vehicles/${id}/${maintainableId}/components/upload`,
  componentsOfComponent: (id: string) => `/components/${id}/children`,
  editComponent: (id: string) => `/components/${id}/edit`,
  viewVehicleStatus: (id: string, maintainableId: string) =>
    `/vehicles/${id}/${maintainableId}/status`,
  listPrograms: "/programs",
  createProgram: "/programs/create",
  editProgram: (id: string) => `/programs/${id}/edit`,
  listProgramInstructions: (id: string) => `/programs/${id}/instructions`,
  createProgramInstruction: (id: string) =>
    `/programs/${id}/instructions/create`,
  editInstruction: (id: string) => `/instructions/${id}/edit`,
  createProgramInstructionModification: (
    programId: string,
    parentProgramId: string
  ) => `/programs/${programId}/${parentProgramId}/instructions-mod/create`,
  editInstructionModification: (id: string) => `/instructions-mod/${id}/edit`,
  listWorkOrders: "/work-orders",
  editWorkOrder: (id: string) => `/work-orders/${id}/edit`,
  listWorkOrderTasks: (id: string) => `/work-orders/${id}/tasks`,
  createWorkOrderTask: (id: string) => `/work-orders/${id}/tasks/create`,
  editTask: (id: string) => `/tasks/${id}/edit`,
  auth: "/login",
  admin: {
    listUsers: "/admin/users",
    createUser: "/admin/users/create",
    editUser: (id: string) => `/admin/users/${id}/edit`,
  },
  profilePreferences: "/me/preferences",
};

export default paths;
