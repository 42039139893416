import { useMutation } from "@apollo/client";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import * as React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { gql } from "~_generated/gql";
import { PageContainer, PageHeadline } from "~layout";
import paths from "~routes/paths";
import { LIST_WORK_ORDER_TASKS } from "./ListWorkOrderTasksPage";
import TaskForm, { TaskFormData } from "./TaskForm";

export const CREATE_TASK = gql(`
  mutation CreateTask($data: CreateTaskInput!) {
    createTask(data: $data) {
      id
    }
  }
`);

function CreateProgramInstructionPage() {
  const navigate = useNavigate();
  const params = useParams();
  const workOrderId = params.id || "";
  const [create, { loading, error }] = useMutation(CREATE_TASK);

  const handleSubmit = (data: TaskFormData) => {
    const variables = {
      data: {
        ...data,
        workOrderId,
      },
    };
    create({ variables, refetchQueries: [LIST_WORK_ORDER_TASKS] }).then(() =>
      navigate(paths.listWorkOrderTasks(workOrderId))
    );
  };

  return (
    <PageContainer>
      <PageHeadline>Creating custom task</PageHeadline>
      {error && <Alert severity="error">{error?.message}</Alert>}
      <TaskForm onSubmit={handleSubmit}>
        <Button
          component={Link}
          to={paths.listWorkOrderTasks(workOrderId)}
          variant="contained"
          color="secondary"
          sx={{ mr: 1 }}
        >
          Back
        </Button>
        <Button type="submit" variant="contained" disabled={loading}>
          Save changes
        </Button>
      </TaskForm>
    </PageContainer>
  );
}

export default CreateProgramInstructionPage;
