import Button from "@mui/material/Button";
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { PageContainer, PageHeadline } from "~layout";
import { PreferencesContext } from "~preferences";
import PreferencesForm, { PreferencesFormData } from "./PreferencesForm";

function UserPreferencesPage() {
  const [data, setData] = useState<PreferencesFormData>();
  const { hoursFormat, setHoursFormat } = useContext(PreferencesContext);

  useEffect(() => {
    setData({
      hoursFormat,
    });
  }, [hoursFormat]);

  const handleSubmit = (data: PreferencesFormData) => {
    setData(data);
    setHoursFormat(data.hoursFormat);
  };

  return (
    <PageContainer>
      <PageHeadline>Personal preferences</PageHeadline>
      {data && (
        <PreferencesForm onSubmit={handleSubmit} data={data}>
          <Button type="submit" variant="contained">
            Save
          </Button>
        </PreferencesForm>
      )}
    </PageContainer>
  );
}

export default UserPreferencesPage;
