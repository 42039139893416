import React from "react";
import { createBrowserRouter, redirect } from "react-router-dom";
import { requestUser } from "~api/auth";
import {
  CreateUserPage,
  EditUserPage,
  ListUsersPage,
} from "~routes/admin/Users";
import {
  CreateVehicleComponentPage,
  EditComponentPage,
  ListChildrenComponentsPage,
  ListVehicleComponentsPage,
  UploadComponentsPage,
} from "~routes/Components";
import {
  CreateProgramInstructionModificationPage,
  CreateProgramInstructionPage,
  EditInstructionModificationPage,
  EditInstructionPage,
  ListProgramInstructionsPage,
} from "~routes/Instructions";
import LoginPage from "~routes/LoginPage";
import paths from "~routes/paths";
import { UserPreferencesPage } from "~routes/Profile";
import {
  CreateProgramPage,
  EditProgramPage,
  ListProgramsPage,
} from "~routes/Programs";
import { RootLayout } from "~routes/Root";
import {
  CreateTaskPage,
  EditTaskPage,
  ListWorkOrderTasksPage,
} from "~routes/Tasks";
import {
  CreateVehiclePage,
  EditVehiclePage,
  ListVehiclesPage,
} from "~routes/Vehicles";
import {
  CreateWorkOrderPage,
  EditWorkOrderPage,
  ListWorkOrdersPage,
} from "~routes/WorkOrders";

const routes = createBrowserRouter([
  {
    path: paths.dashboard,
    element: <RootLayout />,
    loader: async () => {
      const user = await requestUser();
      if (user) {
        return user;
      } else {
        throw redirect(paths.auth);
      }
    },
    children: [
      {
        path: paths.listVehicles,
        element: <ListVehiclesPage />,
      },
      {
        path: paths.editVehicle(":id"),
        element: <EditVehiclePage />,
      },
      {
        path: paths.createVehicle,
        element: <CreateVehiclePage />,
      },
      {
        path: paths.listVehicleComponents(":id"),
        element: <ListVehicleComponentsPage />,
      },
      {
        path: paths.createVehicleComponent(":id", ":maintainableId"),
        element: <CreateVehicleComponentPage />,
      },
      {
        path: paths.uploadVehicleComponents(":id", ":maintainableId"),
        element: <UploadComponentsPage />,
      },
      {
        path: paths.componentsOfComponent(":id"),
        element: <ListChildrenComponentsPage />,
      },
      {
        path: paths.editComponent(":id"),
        element: <EditComponentPage />,
      },
      {
        path: paths.listPrograms,
        element: <ListProgramsPage />,
      },
      {
        path: paths.editProgram(":id"),
        element: <EditProgramPage />,
      },
      {
        path: paths.createProgram,
        element: <CreateProgramPage />,
      },
      {
        path: paths.listProgramInstructions(":id"),
        element: <ListProgramInstructionsPage />,
      },
      {
        path: paths.createProgramInstruction(":id"),
        element: <CreateProgramInstructionPage />,
      },
      {
        path: paths.editInstruction(":id"),
        element: <EditInstructionPage />,
      },
      {
        path: paths.createProgramInstructionModification(
          ":programId",
          ":parentProgramId"
        ),
        element: <CreateProgramInstructionModificationPage />,
      },
      {
        path: paths.editInstructionModification(":id"),
        element: <EditInstructionModificationPage />,
      },
      {
        path: paths.viewVehicleStatus(":id", ":maintainableId"),
        element: <CreateWorkOrderPage />,
      },
      {
        path: paths.listWorkOrders,
        element: <ListWorkOrdersPage />,
      },
      {
        path: paths.editWorkOrder(":id"),
        element: <EditWorkOrderPage />,
      },
      {
        path: paths.listWorkOrderTasks(":id"),
        element: <ListWorkOrderTasksPage />,
      },
      {
        path: paths.createWorkOrderTask(":id"),
        element: <CreateTaskPage />,
      },
      {
        path: paths.editTask(":id"),
        element: <EditTaskPage />,
      },
      {
        path: paths.profilePreferences,
        element: <UserPreferencesPage />,
      },
      {
        path: paths.admin.listUsers,
        element: <ListUsersPage />,
      },
      {
        path: paths.admin.createUser,
        element: <CreateUserPage />,
      },
      {
        path: paths.admin.editUser(":id"),
        element: <EditUserPage />,
      },
    ],
  },
  {
    path: paths.auth,
    element: <LoginPage />,
    loader: async () => {
      const user = await requestUser();
      if (user) {
        throw redirect(paths.dashboard);
      } else {
        return null;
      }
    },
  },
]);
export default routes;
