import { useMutation, useQuery } from "@apollo/client";
import { Delete, Edit } from "@mui/icons-material";
import AddCircle from "@mui/icons-material/AddCircle";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid/Grid";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { gql } from "~_generated/gql";
import CounterValue from "~components/CounterValue";
import { DeleteConfirmationModal } from "~components/modals";
import { PageContainer, PageHeadline } from "~layout";
import paths from "~routes/paths";
import ModificationRuleCounterValue from "./ModificationRuleCounterValue";

export const LIST_MANUAL_INSTRUCTIONS = gql(`
  query ProgramInstructions($id: ID!) {
    program(id: $id) {
      id
      model
      parentId
      instructions {
        items {
          id
          index
          maintainableName
          maintainableIndex
          taskDescription
          intervalRule {
            counters {
              name
              value
            }
            thresholdCounters {
              name
              value
            }
          }
          parameterizedRule {
            parameter
            rules {
              value
              counters {
                name
                value
              }
              thresholdCounters {
                name
                value
              }
            }
          }
        }
        total
      }
      instructionModifications {
        items {
          id
          isIgnored
          index
          instruction {
            index
            maintainableName
            maintainableIndex
            taskDescription
            intervalRule {
              counters {
                name
                value
              }
              thresholdCounters {
                name
                value
              }
            }
            parameterizedRule {
              parameter
              rules {
                value
                counters {
                  name
                  value
                }
                thresholdCounters {
                  name
                  value
                }
              }
            }
          }
          maintainableName
          maintainableIndex
          taskDescription
          intervalRule {
            counters {
              name
              value
            }
            thresholdCounters {
              name
              value
            }
          }
          parameterizedRule {
            parameter
            rules {
              value
              counters {
                name
                value
              }
              thresholdCounters {
                name
                value
              }
            }
          }
        }
        total
      }

    }
  }
`);

export const DELETE_INSTRUCTION = gql(`
  mutation DeleteInstruction($id: ID!) {
    deleteInstruction(id: $id)
  }`);
export const DELETE_INSTRUCTION_MODIFICATION = gql(`
  mutation DeleteInstructionModification($id: ID!) {
    deleteInstructionModification(id: $id)
  }`);

function ListVehiclesPage() {
  const params = useParams();
  const id = params.id || "";
  const { data, error, loading, refetch } = useQuery(LIST_MANUAL_INSTRUCTIONS, {
    variables: { id },
  });
  const [itemToDelete, setItemToDelete] = React.useState<{
    type: "instruction" | "modification";
    id: string;
    index: string;
  }>();

  const [
    deleteInstruction,
    { loading: deletingInstruction, error: deleteInstructionError },
  ] = useMutation(DELETE_INSTRUCTION);
  const handleDeleteInstruction = (id: string) => {
    deleteInstruction({ variables: { id } })
      .then(() => refetch())
      .finally(() => setItemToDelete(undefined));
  };
  const [
    deleteModification,
    { loading: deletingModification, error: deleteModificationError },
  ] = useMutation(DELETE_INSTRUCTION_MODIFICATION);
  const handleDeleteModification = (id: string) => {
    deleteModification({ variables: { id } })
      .then(() => refetch())
      .finally(() => setItemToDelete(undefined));
  };

  const instructions = useMemo(
    () => data?.program?.instructions?.items || [],
    [data]
  );
  const modifications = useMemo(
    () => data?.program?.instructionModifications?.items || [],
    [data]
  );
  const total = instructions.length + modifications.length;

  return (
    <PageContainer loading={loading} error={error?.message}>
      <PageHeadline>AMM for {data?.program?.model}</PageHeadline>
      {deleteInstructionError && (
        <Alert severity="error">{deleteInstructionError?.message}</Alert>
      )}
      {deleteModificationError && (
        <Alert severity="error">{deleteModificationError?.message}</Alert>
      )}
      {total >= 20 && (
        <Grid item xs={12}>
          <Button
            startIcon={<AddCircle />}
            component={Link}
            to={paths.createProgramInstruction(id)}
            variant="contained"
            color="secondary"
            sx={{ mr: 2 }}
          >
            Add Instruction
          </Button>
          {data?.program?.parentId && (
            <Button
              startIcon={<AddCircle />}
              component={Link}
              to={paths.createProgramInstructionModification(
                id,
                data?.program?.parentId
              )}
              variant="contained"
              color="secondary"
              sx={{ mr: 2 }}
            >
              Add Modification
            </Button>
          )}
        </Grid>
      )}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Task No</TableCell>
                  <TableCell>Item name</TableCell>
                  <TableCell>Task</TableCell>
                  <TableCell>Interval hours</TableCell>
                  <TableCell>Interval circles</TableCell>
                  <TableCell>Interval days</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {modifications.map((modification) => (
                  <TableRow
                    key={modification.id}
                    sx={{ opacity: modification.isIgnored ? 0.3 : 1 }}
                  >
                    <TableCell component="th" scope="row">
                      {modification.index || (
                        <Typography color="text.disabled" variant="inherit">
                          {modification.instruction.index}
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell sx={{ minWidth: "8rem" }}>
                      {modification.maintainableName || (
                        <Typography color="text.disabled" variant="inherit">
                          {modification.instruction.maintainableName}
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell sx={{ minWidth: "12rem" }}>
                      {modification.taskDescription || (
                        <Typography color="text.disabled" variant="inherit">
                          {modification.instruction.taskDescription}
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell>
                      <ModificationRuleCounterValue
                        name="hours"
                        modifiedCounters={
                          modification.intervalRule?.counters || undefined
                        }
                        baseCounters={
                          modification.instruction.intervalRule?.counters ||
                          undefined
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <ModificationRuleCounterValue
                        name="circles"
                        modifiedCounters={
                          modification.intervalRule?.counters || undefined
                        }
                        baseCounters={
                          modification.instruction.intervalRule?.counters ||
                          undefined
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <ModificationRuleCounterValue
                        name="days"
                        modifiedCounters={
                          modification.intervalRule?.counters || undefined
                        }
                        baseCounters={
                          modification.instruction.intervalRule?.counters ||
                          undefined
                        }
                      />
                    </TableCell>
                    <TableCell
                      sx={{ whiteSpace: "nowrap", textAlign: "right" }}
                    >
                      <IconButton
                        component={Link}
                        to={paths.editInstructionModification(modification.id)}
                        aria-label="edit"
                      >
                        <Edit />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        onClick={() =>
                          setItemToDelete({
                            id: modification.id,
                            index:
                              modification.index ||
                              modification.instruction.index,
                            type: "modification",
                          })
                        }
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableBody>
                {instructions.map((instruction) => (
                  <TableRow key={instruction.id}>
                    <TableCell component="th" scope="row">
                      {instruction.index}
                    </TableCell>
                    <TableCell sx={{ minWidth: "8rem" }}>
                      {instruction.maintainableName}
                    </TableCell>
                    <TableCell sx={{ minWidth: "12rem" }}>
                      {instruction.taskDescription}
                    </TableCell>
                    <TableCell>
                      <CounterValue
                        name="hours"
                        counters={instruction.intervalRule?.counters}
                      />
                    </TableCell>
                    <TableCell>
                      <CounterValue
                        name="circles"
                        counters={instruction.intervalRule?.counters}
                      />
                    </TableCell>
                    <TableCell>
                      <CounterValue
                        name="days"
                        counters={instruction.intervalRule?.counters}
                      />
                    </TableCell>
                    <TableCell
                      sx={{ whiteSpace: "nowrap", textAlign: "right" }}
                    >
                      <IconButton
                        component={Link}
                        to={paths.editInstruction(instruction.id)}
                        aria-label="edit"
                      >
                        <Edit />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        onClick={() =>
                          setItemToDelete({
                            ...instruction,
                            type: "instruction",
                          })
                        }
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12}>
          <Button
            startIcon={<AddCircle />}
            component={Link}
            to={paths.createProgramInstruction(id)}
            variant="contained"
            color="secondary"
            sx={{ mr: 2 }}
          >
            Add Instruction
          </Button>
          {data?.program?.parentId && (
            <Button
              startIcon={<AddCircle />}
              component={Link}
              to={paths.createProgramInstructionModification(
                id,
                data?.program?.parentId
              )}
              variant="contained"
              color="secondary"
              sx={{ mr: 2 }}
            >
              Add Modification
            </Button>
          )}
        </Grid>
      </Grid>
      {itemToDelete && (
        <DeleteConfirmationModal
          open={Boolean(itemToDelete)}
          deleting={deletingInstruction || deletingModification}
          itemName={itemToDelete.index}
          onCancel={() => setItemToDelete(undefined)}
          onConfirm={() =>
            itemToDelete.type === "instruction"
              ? handleDeleteInstruction(itemToDelete.id)
              : handleDeleteModification(itemToDelete.id)
          }
        />
      )}
    </PageContainer>
  );
}

export default ListVehiclesPage;
