import { useQuery } from "@apollo/client";
import * as React from "react";
import { GET_INSTRUCTION } from "~routes/Instructions/EditInstructionPage";
import InstructionModificationForm, {
  InstructionModFormData,
} from "./InstructionModificationForm";

interface ComponentProps {
  data?: InstructionModFormData;
  instructionId: string;
  onSubmit: (data: InstructionModFormData) => void;
  children: React.ReactNode;
}

function InstructionForm({
  data,
  instructionId,
  onSubmit,
  children,
}: ComponentProps) {
  const { data: queryData } = useQuery(GET_INSTRUCTION, {
    variables: { id: instructionId },
  });
  const instruction = queryData?.instruction;

  if (instruction) {
    return (
      <InstructionModificationForm
        data={data}
        onSubmit={onSubmit}
        instruction={instruction}
      >
        {children}
      </InstructionModificationForm>
    );
  } else {
    return null;
  }
}

export default InstructionForm;
