import { useMutation, useQuery } from "@apollo/client";
import { Delete, Edit } from "@mui/icons-material";
import ListAltIcon from "@mui/icons-material/ListAlt";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid/Grid";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import format from "date-fns/format";
import * as React from "react";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { DELETE_COMPONENT, LIST_COMPONENTS } from "~api/components";
import CounterValue from "~components/CounterValue";
import Loadable from "~components/Loadable";
import { DeleteConfirmationModal } from "~components/modals";
import { locale } from "~config";
import paths from "~routes/paths";

interface Props {
  vehicleId: string;
  parentId: string | null;
}

function ComponentsList({ vehicleId, parentId }: Props) {
  const { data, error, loading } = useQuery(LIST_COMPONENTS, {
    variables: {
      filter: { vehicleId, parentId },
      pagination: { limit: 1000 },
    },
  });
  const [deleteComponent, { loading: deleting, error: deleteError }] =
    useMutation(DELETE_COMPONENT);

  const [itemToDelete, setItemToDelete] = React.useState<{
    id: string;
    name: string;
  }>();

  const handleDelete = (id: string) => {
    deleteComponent({
      variables: { id },
      refetchQueries: [LIST_COMPONENTS],
    }).finally(() => setItemToDelete(undefined));
  };

  const containers = useMemo(
    () => data?.components?.items.filter((item) => item.isContainer) || [],
    [data]
  );
  const children = useMemo(
    () => data?.components?.items.filter((item) => !item.isContainer) || [],
    [data]
  );

  return (
    <Loadable loading={loading} error={error?.message}>
      {deleteError && <Alert severity="error">{deleteError?.message}</Alert>}
      <Grid container spacing={3}>
        {containers.length && (
          <Grid item xs={12}>
            <Typography>Main components</Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Ata No</TableCell>
                    <TableCell>Position</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Part No</TableCell>
                    <TableCell>Serial No</TableCell>
                    <TableCell>Installed at</TableCell>
                    <TableCell>Hours</TableCell>
                    <TableCell>Circles</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {containers.map((component) => (
                    <TableRow
                      key={component.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {component.index}
                      </TableCell>
                      <TableCell>{component.ataNumber}</TableCell>
                      <TableCell>{component.position}</TableCell>
                      <TableCell>{component.name}</TableCell>
                      <TableCell>{component.partNumber}</TableCell>
                      <TableCell>{component.serialNumber}</TableCell>
                      <TableCell>
                        {component.maintainable?.date &&
                          format(new Date(component.maintainable.date), "P", {
                            locale,
                          })}
                      </TableCell>
                      <TableCell>
                        <CounterValue
                          name="hours"
                          counters={component.maintainable?.counters}
                        />
                      </TableCell>
                      <TableCell>
                        <CounterValue
                          name="circles"
                          counters={component.maintainable?.counters}
                        />
                      </TableCell>
                      <TableCell
                        sx={{ whiteSpace: "nowrap", textAlign: "right" }}
                      >
                        <IconButton
                          component={Link}
                          to={paths.componentsOfComponent(component.id)}
                          aria-label="view components"
                        >
                          <ListAltIcon />
                        </IconButton>
                        <IconButton
                          component={Link}
                          to={paths.editComponent(component.id)}
                          aria-label="edit"
                        >
                          <Edit />
                        </IconButton>
                        <IconButton
                          aria-label="delete"
                          onClick={() => setItemToDelete(component)}
                        >
                          <Delete />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
        <Grid item xs={12}>
          {containers.length ? <Typography>Other components</Typography> : null}
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Ata No</TableCell>
                  <TableCell>Position</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Part No</TableCell>
                  <TableCell>Serial No</TableCell>
                  <TableCell>Keyword</TableCell>
                  <TableCell>Installed date</TableCell>
                  <TableCell>Installed hours</TableCell>
                  <TableCell>Installed circles</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {children.map((component) => (
                  <TableRow
                    key={component.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {component.index}
                    </TableCell>
                    <TableCell>{component.ataNumber}</TableCell>
                    <TableCell>{component.position}</TableCell>
                    <TableCell>{component.name}</TableCell>
                    <TableCell>{component.partNumber}</TableCell>
                    <TableCell>{component.serialNumber}</TableCell>
                    <TableCell>{component.keyword}</TableCell>
                    <TableCell>
                      {component.maintainable?.date &&
                        format(new Date(component.maintainable.date), "P", {
                          locale,
                        })}
                    </TableCell>
                    <TableCell>
                      <CounterValue
                        name="hours"
                        counters={component.maintainable?.relativeCounters}
                      />
                    </TableCell>
                    <TableCell>
                      <CounterValue
                        name="circles"
                        counters={component.maintainable?.relativeCounters}
                      />
                    </TableCell>
                    <TableCell sx={{ whiteSpace: "nowrap" }}>
                      <IconButton
                        component={Link}
                        to={paths.editComponent(component.id)}
                        aria-label="edit"
                      >
                        <Edit />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        onClick={() => setItemToDelete(component)}
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      {itemToDelete && (
        <DeleteConfirmationModal
          open={Boolean(itemToDelete)}
          deleting={deleting}
          itemName={itemToDelete.name}
          onCancel={() => setItemToDelete(undefined)}
          onConfirm={() => handleDelete(itemToDelete.id)}
        />
      )}
    </Loadable>
  );
}
export default ComponentsList;
