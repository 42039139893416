import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid/Grid";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import * as React from "react";

interface Props {
  date: Date;
  hours: number;
  circles: number;
  recursive: boolean;
  onChange: (
    date: Date,
    hours: number,
    circles: number,
    recursive: boolean
  ) => void;
}

function SearchInstructionsForm({
  date,
  hours,
  circles,
  recursive,
  onChange,
}: Props) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={recursive}
              onChange={() => onChange(date, hours, circles, !recursive)}
            />
          }
          label="Include components"
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <DatePicker
          label="Date"
          value={date}
          onChange={(value) =>
            value && onChange(value, hours, circles, recursive)
          }
          renderInput={(params) => (
            <TextField {...params} variant="standard" fullWidth />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          type="number"
          label="In hours"
          value={hours}
          onChange={(event) =>
            onChange(
              date,
              Number(event.currentTarget.value),
              circles,
              recursive
            )
          }
          fullWidth
          variant="standard"
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          type="number"
          label="In circles"
          value={circles}
          onChange={(event) =>
            onChange(date, hours, Number(event.currentTarget.value), recursive)
          }
          fullWidth
          variant="standard"
        />
      </Grid>
    </Grid>
  );
}

export default SearchInstructionsForm;
