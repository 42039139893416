import Typography from "@mui/material/Typography";
import * as React from "react";

export function getColor(counter: number, nextCounter: number) {
  const diff = counter - nextCounter;
  if (diff <= 0) {
    return "error.main";
  }
  if (diff <= 20) {
    return "warning.main";
  }
  return "success.main";
}

interface Props {
  counter: number;
  maintenanceCounter: number;
}

function NextCounterValue({ counter, maintenanceCounter }: Props) {
  return (
    <Typography variant="inherit" color={getColor(counter, maintenanceCounter)}>
      {counter}
    </Typography>
  );
}
export default NextCounterValue;
