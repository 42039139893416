import { useQuery } from "@apollo/client";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { SelectProps } from "@mui/material/Select/Select";
import * as React from "react";
import { useMemo } from "react";
import { LIST_MANUAL_INSTRUCTIONS } from "~routes/Instructions/ListProgramInstructionsPage";

interface Props extends SelectProps {
  programId: string;
  selectedId?: string | null;
  onSelectId: (id: string) => void;
}

function InstructionSelector({
  programId,
  selectedId,
  onSelectId,
  ...props
}: Props) {
  const { data } = useQuery(LIST_MANUAL_INSTRUCTIONS, {
    variables: { id: programId },
  });
  const instructions = useMemo(
    () => data?.program?.instructions?.items || [],
    [data]
  );

  return (
    <Select
      value={selectedId || ""}
      onChange={(event) => onSelectId(String(event.target.value))}
      {...props}
    >
      {instructions.map((instruction) => (
        <MenuItem value={instruction.id} key={instruction.id}>
          {instruction.index}: {instruction.maintainableName}
        </MenuItem>
      ))}
    </Select>
  );
}

export default InstructionSelector;
