import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import * as React from "react";

export type UploadErrorReport = Array<{
  record: Array<string>;
  error: { message: string };
}>;

interface Props {
  errors: UploadErrorReport;
}

function UploadErrors({ errors }: Props) {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          {errors.map(({ record, error }, index) => (
            <>
              <TableRow key={index + "_error"}>
                <TableCell colSpan={10}>{error.message}</TableCell>
              </TableRow>
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {record[0]}
                </TableCell>
                <TableCell>{record[1]}</TableCell>
                <TableCell>{record[2]}</TableCell>
                <TableCell>{record[3]}</TableCell>
                <TableCell>{record[4]}</TableCell>
                <TableCell>{record[5]}</TableCell>
                <TableCell>{record[6]}</TableCell>
                <TableCell>{record[7]}</TableCell>
                <TableCell>{record[8]}</TableCell>
                <TableCell>{record[9]}</TableCell>
              </TableRow>
            </>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default UploadErrors;
