import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import IconButton from "@mui/material/IconButton";
import * as React from "react";
import { ChangeEvent, useState } from "react";
import config from "~config";

interface Props {
  workOrderId: string;
  onSuccess?: (fileName: string) => void;
  onError?: (error?: Error) => void;
}

function upload(file: File, workOrderId: string) {
  let formData = new FormData();
  formData.append("file", file);
  return fetch(`${config.uploadUrl}/work-orders/${workOrderId}/documents`, {
    method: "POST",
    body: formData,
  });
}

function UploadDocumentButton({ workOrderId, onSuccess, onError }: Props) {
  const [uploadKey, setUploadKey] = useState<number>(Math.random());
  const [loading, setLoading] = useState(false);
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setLoading(true);
      upload(e.target.files[0], workOrderId)
        .then((response) => {
          if (!response.ok) {
            if (response.status === 422) {
              throw Error("File with this name already exists");
            } else {
              throw Error(response.statusText);
            }
          }
          return response.json();
        })
        .then((data) => onSuccess && onSuccess(data))
        .catch((error) => onError && onError(error))
        .finally(() => setLoading(false));
      setUploadKey(Math.random());
    }
  };

  return (
    <IconButton
      color="secondary"
      component="label"
      disabled={loading}
      onClick={() => onError && onError(undefined)}
    >
      <AddCircleOutlineIcon />
      <input type="file" onChange={handleFileChange} hidden key={uploadKey} />
    </IconButton>
  );
}
export default UploadDocumentButton;
